@charset "utf-8";

/* initialize */
body {
  margin: 0;
  padding: 0;
  background-color: #fff;
  color: #4d4d4d;
  font-size: 12px;
  line-height: 1;
  -webkit-text-size-adjust: none;
  -ms-text-size-adjust: none;
}
html,
body {
  /*width:100%; height:100%; */ /*min-width:1024px; */
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
article,
aside,
canvas,
footer,
header,
menu,
nav,
section,
summary,
video {
  z-index: inherit;
  padding: 0;
  border: 0;
  margin: 0;
  vertical-align: baseline;
  font: inherit;
  font-size: 100%;
  font-family: 'NanumSquare';
}

input[type='button'],
input[type='text'],
input[type='image'],
input[type='submit'],
input[type='password'],
input[type='tel'],
input[type='number'],
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 0;
  outline: 0;
  vertical-align: middle;
}
button {
  font-family: 'NanumSquare';
}
select:focus,
button:focus {
  outline: 0;
}
input[type='checkbox'] {
  -webkit-appearance: checkbox;
  -moz-appearance: checkbox;
}
input[type='radio'] {
  -webkit-appearance: radio;
  -moz-appearance: radio;
}
input::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
}
::selection {
  color: rgb(255, 255, 255);
  background: #1aaaba;
}

li {
  list-style: none;
}
table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}
th,
td {
  border-collapse: collapse;
}
legend,
caption,
hr {
  display: none;
}
a {
  text-decoration: none;
  color: #4d4d4d;
}
a:hover {
  color: #1aaaba;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#root {
  height: 100%;
}

#app-root {
  height: 100%;
}

@media screen and (min-width: 0\0) {
  table {
    border-collapse: inherit;
  }
}

.table-typeN td:nth-child(7) .ellipsis,
.table-typeI td:nth-child(2),
.table-typeB .scroll p,
.table-typeL td:first-child,
.table-typeB .help,
.multi-lang,
.table-typeB .link.token,
[class^='txt-type'],
.table-typeA.contract td:nth-child(2),
.table-typeC.token td:nth-child(7),
.table-typeC.token td:nth-child(7) span,
.table-typeB .combo-layer .list-group li p:first-child em {
  font-family: Arial, Microsoft YaHei, sans-serif !important;
}

.table-typeI td:nth-child(2),
.table-typeB .scroll p,
.table-typeB .link.token {
  font-weight: 600 !important;
}

/*txt*/
[class^='txt-type'] {
  color: #fff;
  background-color: transparent;
  font-size: 12px;
  border: none;
  border-bottom: 2px solid rgba(255, 255, 255, 0.4);
  padding: 12px 40px 12px 10px;
  font-family: 'NanumSquare';
  font-weight: 500;
}
.txt-type-normal {
  position: relative;
  width: 100%;
}
.txt-type-normal:focus {
  border-bottom: 2px solid #fff;
}
.txt-type-normal.fail {
  border-bottom: 1px solid #f44336;
}
.txt-type-normal:disabled {
  color: #dddddd;
}

input:-ms-input-placeholder {
  color: #a7d7de;
}
input::-webkit-input-placeholder {
  color: #a7d7de;
}
input::-moz-placeholder {
  color: #a7d7de;
}
input::-moz-placeholder {
  color: #a7d7de;
}

.txt-type-page {
  position: relative;
  width: 100px;
  font-size: 16px;
  border: 1px solid #262626;
  color: #4d4d4d;
  padding: 14px 10px;
}
input.txt-type-search {
  position: relative;
  width: 355px;
  font-size: 12px;
  height: 28px;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  color: #4d4d4d;
  padding: 12px 10px 12px;
}
input#sub-search-input {
margin-top: -41px;
}
input.txt-type-search:hover {
  border: 1px solid #4d4d4d;
}
input.txt-type-search:focus {
  border: 1px solid #1aaaba;
  color: #1aaaba;
}
/*btn*/
.btn-holder {
}

[class^='btn-type'] {
  display: inline-block;
  color: #808080;
  background-color: #fff;
  cursor: pointer;
  font-size: 12px;
  height: 26px;
  padding: 0px 23px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  line-height: 26px;
  font-weight: 400;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
}
[class^='btn-type']:hover {
  border: 1px solid #1aaaba;
  color: #1aaaba;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.05);
}
.btn-type-fill {
  background: #262626;
  color: #fff;
}
.btn-type-fill:hover {
  background: #000;
}
[class^='btn-type']:disabled {
  color: #ccc;
  cursor: default;
  background-color: #fff;
  border-color: #ccc;
}
[class^='btn-type']:disabled:hover {
  background: #fff !important;
  color: #ccc !important;
  border-color: #ccc !important;
  box-shadow: none !important;
}

.btn-type-query {
  border-radius: 0 2px 2px 0;
  background: #fff;
  margin-left: -4px;
  box-shadow: none;
}

[class^='btn-type'].on {
  background: #1aaaba;
  color: #fff;
  border: 1px solid #1aaaba;
}
[class^='btn-type'].on:hover {
  background: #1aaaba !important;
  color: #fff !important;
  border: 1px solid #1aaaba !important;
}
/*radio*/
.rbox-holder {
}
[class^='rbox-type'] {
  position: absolute;
  left: -99999px;
}
[class^='rbox-type'] + label {
  height: 16px;
  line-height: 16px;
  padding: 0 25px;
  background-color: #fff;
  background: url(../image/rbox.png) left center no-repeat;
  background-position: 0 0;
  display: inline-block;
  cursor: pointer;
  font-size: 22px;
}
input[type='radio']:checked + label {
  background-position: 0 -20px;
}
input[type='radio']:disabled + label {
  background-position: 0 -40px;
  cursor: default;
}
input[type='radio'][disabled][checked] + label {
  background-position: 0 -60px;
  cursor: default;
}

/*select*/
.sbox-holder {
  display: inline-block;
  width: auto;
  vertical-align: middle;
}
[class^='sbox-type'] {
  color: #fff;
  border: none;
  padding: 15px 20px;
  border-bottom: solid 2px #fff;
  background-color: transparent;
  background: url(../image/sbox.png) right 10px center no-repeat, 8px 6px;
  background-size: 8px auto;
}
.sbox-type-normal {
}

/*common*/
html,
body {
  height: 100%;
}

.none {
  display: none;
}

[class*='img'] {
  background: url(../image/common.png) left top no-repeat;
  background-size: 350px auto;
  display: inline-block;
}
[class*='coin'] {
  background: url(../image/common_coin.png) left top no-repeat;
  background-size: 100px auto;
  display: inline-block;
}

.im_g {
  display: inline-block;
}

.content-wrap [class^='screen'] {
  margin: 0 auto;
  min-width: 1210px;
}
.wrap-holder {
  margin: 0 auto;
  width: 1260px;
  position: relative;
  padding: 0 50px;
}
.blank {
  height: 144px;
  position: relative;
  background: #fff;
}
.tx-blank {
  min-height: 200px;
  position: relative;
  background: black;
}
.content.half > li {
  width: 50%;
}
/*////////////////////////////////////////////////////////////////*/
/*header*/
.header-wrap {
  position: fixed;
  width: 100%;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  z-index: 10;
  background: #00b8cc;
  height: 60px;
  top: 0;
}
.header-wrap .wrap-holder {
  max-width: 1260px;
  min-width: 980px;
  width: auto;
  height: 60px;
}
.header-wrap .wrap-holder .content {
  text-align: center;
  position: relative;
}
.header-wrap li {
  display: inline-block;
}
.header-wrap .logo {
  
  background: url(../image/2022Logo.png)
  left top no-repeat;
  background-size: cover;
  width: 130px;
  height: 24px;
  display: inline-block;
  vertical-align: top;
  margin-top: 0px;
  position: absolute;
  left: 0;
  top: 18px;
}
.header-wrap .logo em {
  font-weight: 500;
  font-size: 20px;
  margin: 7px 96px;
  display: inline-block;
  color: #fff;
}
.header-wrap .link {
  display: inline-block;
  margin-top: 20px;
}
.header-wrap .link ul {
  display: inline-block;
  font-weight: 400;
  vertical-align: text-top;
  font-size: 0;
}
.header-wrap .link > ul > li {
  display: inline-block;
  margin: 0 25px;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.5);
  vertical-align: top;
  font-size: 16px;
  letter-spacing: -0.5px;
  text-align: center;
  position: relative;
}
.header-wrap .link > ul > li > span {
  height: 31px;
  display: inline-block;
  font-family: 'Montserrat';
  transition: color 0.2s;
  letter-spacing: -0.5px;
  font-size: 14px;
  font-weight: 500;
}

.header-wrap .link > ul > li > span:hover {
  color: rgba(255, 255, 255, 1);
}
.header-wrap .link > ul > li.on > span {
  color: rgba(255, 255, 255, 1);
}
.header-wrap .link > ul > li > span .img {
  background-position: -250px -50px;
  width: 7px;
  height: 5px;
  margin: 2px auto 0;
  display: block;
  vertical-align: middle;
}
.header-wrap .link > ul > li.on > span .img {
  background-position: -250px -100px;
}

.header-wrap .link-right {
  display: inline-block;
  position: absolute;
  right: 0;
  top: 22px;
}
.header-wrap .link-right .search-group {
  display: inline-block;
  position: relative;
}
.header-wrap .link-right .search-group span {
  position: absolute;
  right: 0px;
  top: -26px;
  width: 40px;
  height: 40px;
  cursor: pointer;
}
.header-wrap .link-right .search-group span .img {
  width: 16px;
  height: 16px;
  background-position: -300px -50px;
  margin: 13px 12px;
}

.header-wrap .link > ul > li:hover {
  color: rgba(255, 255, 255, 1);
}
.header-wrap .link li:nth-child(1) .sub-menu {
  left: -18px;
}
.header-wrap .link li:nth-child(4) .sub-menu {
  left: -18px;
  min-width: 123px;
}

.header-wrap .sub-menu {
  position: absolute;
  color: #fff;
  display: none;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.15);
  font-size: 12px;
  font-weight: 300;
  background: #fff;
  border-radius: 4px;
  overflow: hidden;
  padding: 12px 0;
}

.header-wrap .sub-menu li:first-child {
  border-top: 2px solid #fff;
}
.header-wrap .sub-menu li {
  display: block;
  padding: 0 20px;
  height: 34px;
  color: #4d4d4d;
  text-align: left;
  line-height: 34px;
}
.header-wrap .sub-menu li:hover {
  background: rgba(26, 170, 186, 0.1);
  color: #1aaaba;
}
.header-wrap .sub-menu li span {
  font-family: 'Montserrat';
  font-weight: 500;
  letter-spacing: -0.5px;
}
.header-wrap .connect {
  display: inline-block;
  margin-right: 60px;
  position: relative;
}
.header-wrap .connect > span {
  cursor: pointer;
  display: inline-block;
  color: rgba(255, 255, 255, 0.5);
}
.header-wrap .connect > span:hover {
  color: rgba(255, 255, 255, 1);
}
.header-wrap .connect > span em {
  background-position: 10px 10px;
  width: 7px;
  height: 5px;
  margin: 2px auto 0;
  display: block;
  vertical-align: middle;
}
.header-wrap .connect > span.disabled {
  opacity: 0.5;
  pointer-events: none;
}
.header-wrap .connect > span.disabled + .sub-menu {
  opacity: 0;
  pointer-events: none;
}

.header-wrap .connect.join em {
  background-position: -250px -50px;
}
.header-wrap .connect.join > span:hover em {
  background-position: -250px -100px;
}
.header-wrap .connect .sub-menu {
  width: 361px;
  height: 108px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.15);
  position: absolute;
  right: 0px;
  top: 24px;
  font-size: 0;
  overflow: hidden;
  padding: 0;
}
.header-wrap .connect .sub-menu p {
  margin: 14px 20px;
  height: 50px;
  padding-top: 9px;
}
.header-wrap .connect .sub-menu p span {
  display: block;
}
.header-wrap .connect .sub-menu p span:nth-child(1) {
  font-size: 12px;
  color: #4d4d4d;
  font-family: 'Montserrat';
  margin: 0 0 5px;
  font-weight: 500;
}
.header-wrap .connect .sub-menu p span:nth-child(2) {
  font-size: 10px;
  color: #b3b3b3;
  cursor: pointer;
}
.header-wrap .connect .sub-menu p span:nth-child(2) em:nth-child(2) {
  margin-left: 4px;
  visibility: hidden;
}
.header-wrap .connect .sub-menu p span:nth-child(2):hover {
  color: #4d4d4d;
}
.header-wrap .connect .sub-menu p span:nth-child(2):hover em {
  visibility: visible;
}
.header-wrap .connect .sub-menu p span:nth-child(2):hover em:nth-child(1) {
  text-decoration: underline;
}
.header-wrap .connect .sub-menu .complete em {
  color: #1daab9;
  visibility: visible !important;
}

.header-wrap .connect .sub-menu .btn {
  width: 50%;
  height: 30px;
  background: #f5f5f5;
  color: #333;
  display: inline-block;
  text-align: center;
  font-size: 12px;
  line-height: 2.5;
  cursor: pointer;
  font-weight: 400;
}

.header-wrap .connect .sub-menu .btn:nth-of-type(1) {
  border-right: 1px solid #fff;
}
.header-wrap .connect .sub-menu .btn:hover {
  background: #ebebeb;
}

/*home*/
.root {
  position: relative;
  min-height: 100%;
  margin: 0 auto -52px;
}
.wrap {
  position: relative;
}
.content-wrap .bar {
  background: #1aaaba;
  position: absolute;
}

.content-wrap .wrap-holder > .title {
  font-family: 'Montserrat';
  position: relative;
  letter-spacing: -0.5px;
  margin-left: 20px;
}
.content-wrap .wrap-holder > .title i {
  width: 30px;
  height: 30px;
  display: inline-block;
  margin-right: 6px;
  position: absolute;
  top: -3px;
  left: 46px;
}
.content-wrap .wrap-holder > .title i img {
  width: 100%;
}
.content-wrap .title {
  font-size: 24px;
  font-weight: 600;
  font-family: 'Montserrat';
}
.content-wrap .title span {
  font-size: 12px;
  color: #b3b3b3;
  font-weight: 500;
  margin-left: 10px;
  letter-spacing: 0;
}
.content-wrap .title .right {
  float: right;
  margin: 14px 30px 0 0;
  color: #4d4d4d;
  font-weight: 400;
}
.content-wrap .title .right em {
  font-weight: 600;
  margin-left: 6px;
}
.content-wrap .title .right em:nth-child(2) {
  color: #b3b3b3;
  font-weight: 300;
  cursor: default;
  text-decoration: none;
}
.content-wrap .title .left {
  color: #4d4d4d;
}
.content-wrap .title .right.cont {
  margin: 8px 0 0 0;
}
.content-wrap .title span .mint {
  color: #1aaaba;
  font-weight: 600;
}

.content-wrap .title .connected {
  height: 26px;
  font-weight: 600;
  border-radius: 13px;
  background: #e6f8fa;
  padding: 7px 15px;
  color: #1aaaba;
  position: relative;
  top: -4px;
  margin-left: 20px;
  top: 0;
}
.content-wrap .title .connected .img {
  background-position: -100px -250px;
  width: 14px;
  height: 9px;
  position: relative;
  top: 2px;
  left: auto;
}
.content-wrap .title .toggle {
  position: absolute;
  right: 0;
  color: #4d4d4d;
  font-weight: 600;
  top: 10px;
}
.content-wrap .title .toggle em:first-child {
  margin-right: 20px;
}
.content-wrap .title .toggle em span {
  margin-left: 4px;
}
.content-wrap .title .title-tag {
  height: 26px;
  font-weight: 600;
  border-radius: 13px;
  background: #e6f8fa;
  padding: 7px 15px;
  color: #1aaaba;
  position: relative;
  top: -4px;
  margin-left: 20px;
  top: -4px;
}
.content-wrap .title .title-tag.unregist {
  background: #ffedeb;
  color: #f24130;
}
.content-wrap .wrap-holder > .title.token .right .gray {
  color: #b3b3b3;
  font-weight: 400;
}
.content-wrap .wrap-holder > .title.token span:first-child {
  font-size: 24px;
  color: #4d4d4d;
}
.content-wrap .subTitle {
  font-size: 16px;
  font-weight: 300;
  color: rgba(255, 255, 255, 1);
  font-family: 'Montserrat';
}

.content-wrap li {
  display: inline-block;
}

.home .content-wrap {
  margin-top: 60px;
}
.home .content-wrap .screen0 {
  margin: 50px 0 30px;
  width: 100%;
}
.home .content-wrap .screen0 .wrap-holder {
  padding: 0px;
  width: 1160px;
}
.home .content-wrap .screen1 .bg {
  width: 100%;
  position: relative;
}
.home .content-wrap .screen0 .content {
  font-size: 0;
}
.home .content-wrap .screen0 .content li {
  position: relative;
  margin-right: 12px;
}
.home .content-wrap .screen0 .content li:last-child {
  margin-right: 0;
}
.home .content-wrap .screen0 .content li p:nth-of-type(1) {
  font-size: 12px;
  margin-bottom: 14px;
  color: #b3b3b3;
  text-align: right;
}
.home .content-wrap .screen0 .content li p em {
  font-weight: 300;
}
.home .content-wrap .screen0 .content li p:last-child {
  font-size: 24px;
  text-align: right;
  letter-spacing: -1.8px;
  font-weight: 700;
}
.home .content-wrap .screen0 .content li div {
  width: 281px;
  height: 100px;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px #d9d9d9;
  padding: 20px;
  position: relative;
}
.home .content-wrap .screen0 .content li div span {
  width: 30px;
  height: 30px;
  background: #4d4d4d;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  left: 20px;
  top: 20px;
  color: #fff;
  text-align: center;
}
.home .content-wrap .screen0 .content li div span .img {
  background-position: 100px 100px;
}
.home .content-wrap .screen0 .content li div span.usd {
  background: #f5a916;
}
.home .content-wrap .screen0 .content li div span.usd .img {
  background-position: -200px -500px;
}
.home .content-wrap .screen0 .content li div span.icx {
  background: #00b8cc;
}
.home .content-wrap .screen0 .content li div span.icx .img {
  background-position: -175px -500px;
}
.home .content-wrap .screen0 .content li div span i {
  font-size: 16px;
  font-weight: 700;
  display: inline-block;
  margin-top: 6px;
  width: 18px;
  height: 18px;
  line-height: 22px;
}

.home .content-wrap .screen1 .wrap-holder {
  padding: 0px;
  width: 1160px;
}
.home .content-wrap .screen1 .content .title span {
  display: inline-block;
  float: right;
  cursor: pointer;
  font-size: 12px;
  margin-top: 12px;
  font-weight: 500;
  color: #4d4d4d;
  font-family: 'Montserrat';
}
.home .content-wrap .screen1 .content .title .img {
  width: 6px;
  height: 12px;
  background-position: 0px 0;
  margin: -2px 0 -2px 9px;
}
.home .content-wrap .screen1 .content .title span:hover {
  color: #1aaaba;
}
.home .content-wrap .screen1 .content .title span:hover .img {
  background-position: 0px -20px;
}

.home .content-wrap .screen1 .content > li {
  position: relative;
  vertical-align: top;
  width: 572px;
  margin-bottom: 10px;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px #d9d9d9;
}
.home .content-wrap .screen1 .content > li:not(:first-child) {
  margin-left: -6px;
}

.home .content-wrap .screen1 .content em.scam {
  color: #f24130 !important;
}
.home .content-wrap .screen1 .content em.scam .img {
  width: 12px;
  height: 12px;
  background-position: -125px 0px;
  vertical-align: middle;
  margin-right: 5px;
}

.home .content-wrap .screen1 .content .list-group {
  margin: 30px 0;
}
.home .content-wrap .screen1 .content .list-group .a,
.home .content-wrap .screen1 .content .list-group .c {
  color: #1aaaba;
}
.home .content-wrap .screen1 .content .list-group .list li p.b,
.home .content-wrap .screen1 .content .list-group .list li p.d {
  padding-left: 50px;
}
.home .content-wrap .screen1 .content .list-group .list li p.b em,
.home .content-wrap .screen1 .content .list-group .list li p.d em {
  left: 134px;
}
.home .content-wrap .screen1 .content .right .list-group .list li p.b,
.home .content-wrap .screen1 .content .right .list-group .list li p.d {
  padding-left: 60px;
}
.home .content-wrap .screen1 .content .right .list-group .list li p.b em,
.home .content-wrap .screen1 .content .right .list-group .list li p.d em {
  text-align: right;
  padding-right: 30px;
}
.home .content-wrap .screen1 .content .list-group .list li.fail {
}
.home .content-wrap .screen1 .content .list-group .list li.fail .icon {
  background: #f24c3d;
}
.home .content-wrap .screen1 .content .list-group .list li.fail .a,
.home .content-wrap .screen1 .content .list-group .list li.fail .c {
  color: #f24130;
}

.fade {
  animation: fadeIn linear .5s;
  -webkit-animation: fadeIn linear .5s;
  -moz-animation: fadeIn linear .5s;
  -o-animation: fadeIn linear .5s;
  -ms-animation: fadeIn linear .5s;

}
@keyframes fadeIn {
  0% {opacity:.3;}
  100% {opacity:1;}
}

@-moz-keyframes fadeIn {
  0% {opacity:.3;}
  100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
  0% {opacity:.3;}
  100% {opacity:1;}
}

@-o-keyframes fadeIn {
  0% {opacity:.3;}
  100% {opacity:1;}
}

@-ms-keyframes fadeIn {
  0% {opacity:.3;}
  100% {opacity:1;}
}
.flat {
  opacity: 1;
  transition: .05s;
}
.fade-in-anim{
  animation: fadeIn 0.5s ;
}


.home .content-wrap .screen1 .content .list {
  font-size: 12px;
  font-weight: 700;
}
.home .content-wrap .screen1 .content .list li {
  display: block;
  height: 80px;
  padding: 18px 0;
  margin: 0 30px;
  position: relative;
  font-weight: 700;
}
.home .content-wrap .screen1 .content .list li .icon {
  width: 44px;
  height: 44px;
  background: #4d4d4d;
  border-radius: 50%;
  position: absolute;
  margin-top: -2px;
  text-align: center;
  color: #fff;
  font-size: 20px;
  line-height: 46px;
}
.home .content-wrap .screen1 .content .left .list li .icon {
  border-radius: 5px;
}

.home .content-wrap .screen1 .content .list li p:not(.icon) {
  padding-left: 68px;
  margin-top: 4px;
  position: relative;
  display: inline-block;
  width: 243px;
  font-weight: 500;
}
.home .content-wrap .screen1 .content .list li p em {
  font-weight: 700;
  position: absolute;
  left: 112px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 165px;
}
.home .content-wrap .screen1 .content .list li .c em {
  cursor: pointer;
}
.home .content-wrap .screen1 .content .list li .c em:hover {
  text-decoration: underline;
}

.home .content-wrap .screen1 .content .list li em span {
  color: #b3b3b3;
  margin-left: 5px;
}
.home .content-wrap .screen1 .content .left {
  margin-right: 14px;
}
.home .content-wrap .screen1 .content .right {
  margin-left: 6px;
}
.home .content-wrap .screen1 .content .list li .a em a,
.home .content-wrap .screen1 .content .left .list li p em a {
  color: #1aaaba;
}
.home .content-wrap .screen1 .content .left .list li p em.scam a {
  color: #f24130;
}
.home .content-wrap .screen1 .content .left .list li p em {
  left: 108px;
}

.home .content-wrap .screen1 .content .list li p em a {
  text-decoration: none;
  color: #1aaaba;
}
.home .content-wrap .screen1 .content .list li p em a:hover {
  text-decoration: underline;
}

.home .content-wrap .screen1 .content .left .list li .icon span:nth-child(3) a {
  color: #1aaaba;
  cursor: pointer;
}
.home
  .content-wrap
  .screen1
  .content
  .left
  .list
  li
  .icon
  span:nth-child(3):hover
  a {
  text-decoration: underline;
}

.home .content-wrap .screen1 .content .right .list li .icon img {
  position: absolute;
  left: 20px;
  width: 60px;
}
.home .content-wrap .screen1 .content .right .list li .icon span {
  position: relative;
  z-index: 1;
  font-weight: 500;
  display: block;
  font-size: 9px;
  margin-top: 20px;
}
.home .content-wrap .screen1 .title {
  font-size: 14px;
  text-align: center;
  height: 40px;
  background: #f7f7f7;
  line-height: 40px;
  font-weight: 700;
  border-bottom: 1px solid #d9d9d9;
}
.home .content-wrap .screen1 .all {
  font-size: 12px;
  text-align: center;
  height: 40px;
  line-height: 40px;
  color: #808080;
  border-top: 1px solid #d9d9d9;
  cursor: pointer;
}
.home .content-wrap .screen1 .all:hover {
  color: #1aaaba;
}
.home .content-wrap .screen2 {
  height: 260px;
  background: url(../image/img-main-bg.png) center center no-repeat, #00b8cc;
  background-size: auto 260px, 200%;
  min-width: 1260px;
}
.home .content-wrap .screen2 .content {
  color: #fff;
  text-align: center;
}
.home .content-wrap .screen2 .content p {
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  font-size: 34px;
  font-weight: 600;
  letter-spacing: -0.5px;
  font-family: 'Montserrat';
  padding-top: 63px;
}
.home .content-wrap .screen2 .content .search-group {
  margin-top: 20px;
  position: relative;
  width: 592px;
  height: 48px;
  display: inline-block;
}
.home .content-wrap .screen2 .content input.txt-type-search {
  width: 100%;
  height: 100%;
  border-radius: 3px;
  background: rgba(255, 255, 255, 0.3);
  border: none;
  font-size: 14px;
  padding: 16px 100px 16px 20px;
  color: #fff;
}
.home .content-wrap .screen2 .content input.txt-type-search:focus {
  background: #fff;
  color: #4d4d4d;
}
.home .content-wrap .screen2 .content input.txt-type-search:focus + span .img {
  background-position-x: -125px;
}
.home .content-wrap .screen2 .search-group span {
  width: 22px;
  height: 22px;
  top: 13px;
  right: 20px;
}
.home .content-wrap .screen2 .search-group span .img {
  width: 22px;
  height: 22px;
  background-position: -100px -500px;
  margin: 0;
}
.home .content-wrap .screen2 .search-group em {
  position: absolute;
  right: 62px;
  top: 14px;
  width: 20px;
  height: 20px;
  display: none;
  cursor: pointer;
}
.home .content-wrap .screen2 .search-group em .img {
  width: 20px;
  height: 20px;
  background-position: -25px -125px;
}
.home .content-wrap .screen2 input:-ms-input-placeholder {
  color: #fff;
}
.home .content-wrap .screen2 input::-webkit-input-placeholder {
  color: #fff;
}
.home .content-wrap .screen2 input::-moz-placeholder {
  color: #fff;
}
.home .content-wrap .screen2 input::-moz-placeholder {
  color: #fff;
}

/*sub*/
.table-box {
  margin-top: 16px;
  border-radius: 3px;
  border: solid 1px #d9d9d9;
}
.sub .content-wrap {
  padding-top: 114px;
}
.sub .content-wrap .screen1 {
  margin-top: 50px;
}
[class*='table-type'] a {
  text-decoration: none;
  color: #1aaaba;
}
[class*='table-type'] .scam a {
  color: #f24130;
}
[class*='table-type'] a:hover {
  text-decoration: underline;
  opacity: 0.5;
}
[class*='table-type'] {
  font-size: 12px;
}
[class*='table-type'] .on {
  color: #1aaaba;
}
[class*='table-type'] .black {
  color: #f24130;
}
[class*='table-type'] .scam {
  color: #f24130;
}
[class*='table-type'] td.on span {
  font-weight: 700;
}
[class*='table-type'] .break {
  word-break: break-all;
}
[class*='table-type'] th,
[class*='table-type'] td {
  font-weight: 500;
}
[class*='table-type'] thead tr {
  height: 40px;
}
[class*='table-type'] thead tr th {
  background: #f5f5f5;
  text-align: center;
  color: #808080;
  border-bottom: 1px solid #d9d9d9;
}
[class*='table-type'] thead tr th:not(:first-child) {
  border-left: 1px solid #e6e6e6;
}
[class*='table-type'] thead th {
  vertical-align: middle;
  font-weight: 400;
}
[class*='table-type'] thead th .img {
  background-position: 0 -50px;
  width: 7px;
  height: 5px;
}
[class*='table-type'] thead th em {
  color: #b3b3b3;
  margin-left: 6px;
  font-weight: 400;
}
[class*='table-type'] td {
  border-bottom: 1px solid #d9d9d9;
  border-left: 1px solid #e6e6e6;
  padding: 11px 20px 5px;
  vertical-align: middle;
  position: relative;
}
[class*='table-type'] td:first-child {
  border-left: none;
}
[class*='table-type'] tr:last-child td {
  border-bottom: none;
}
[class*='table-type'] td span {
  font-weight: 700;
}
[class*='table-type'] td em {
  display: inline-block;
  text-align: left;
  margin-left: 6px;
  font-weight: 300;
  color: #4d4d4d;
}
[class*='table-type'] td.no {
  text-align: center !important;
}
[class*='table-type'] thead th span.sort {
  font-weight: 700;
  color: #404040;
  cursor: pointer;
}

.table-typeA td:nth-child(2),
.table-typeA td:nth-child(3),
.table-typeA td:nth-child(4),
.table-typeA td:nth-child(5) {
  text-align: right;
}
.table-typeA td:nth-child(6) {
  text-align: center;
}
.table-typeA th:nth-child(2) {
  width: 214px;
}
.table-typeA th:nth-child(3) {
  width: 214px;
}
.table-typeA th:nth-child(4) {
  width: 124px;
}
.table-typeA th:nth-child(5) {
  width: 124px;
}
.table-typeA th:nth-child(6) {
  width: 124px;
}
.table-typeA tr td.icon span {
  width: 300px;
}

.table-typeA.contract td:nth-child(2) {
  text-align: left;
}
.table-typeA.contract td:nth-child(3),
.table-typeA.contract td:nth-child(4) {
  text-align: right;
}
.table-typeA.contract td:nth-child(4),
.table-typeA.contract td:nth-child(5),
.table-typeA.contract td:nth-child(6) {
  text-align: center;
}
.table-typeA.contract th:nth-child(2) {
  width: 300px;
}
.table-typeA.contract th:nth-child(3) {
  width: 250px;
}
.table-typeA.contract th:nth-child(4) {
  width: 84px;
}
.table-typeA.contract th:nth-child(5) {
  width: 120px;
}
.table-typeA.contract th:nth-child(6) {
  width: 147px;
}
.table-typeA.contract td:nth-child(1) span {
  width: 240px;
}
.table-typeA td {
  position: relative;
}
[class*='table-type'] td.icon a {
  margin-left: 0px;
}
[class*='table-type'] td.icon span {
  margin-left: 16px;
  width: 282px;
}
[class*='table-type'] tr i.img {
  width: 10px;
  height: 12px;
  background-position: -50px 0;
  position: absolute; /* top: 10px;*/
}
[class*='table-type'] td.icon.error i.img {
  width: 14px;
  height: 14px;
  background-position: -100px 0;
  position: absolute;
  top: 12px;
}
[class*='table-type'] td.icon.calen i.img {
  width: 12px;
  height: 12px;
  background-position: 0px -250px;
  position: absolute;
  top: 12px;
}
[class*='table-type'] td.icon.scam i.img {
  width: 12px;
  height: 12px;
  background-position: -125px 0px;
  position: absolute;
  top: 12px;
}
[class*='table-type'] td.icon.calen span {
  font-size: 11px;
}
[class*='table-type'] td.icon.calen {
  padding: 8px 20px 5px;
}
[class*='table-type'] .prep-tag {
  height: 18px;
  border-radius: 10px;
  background: #e6f8fa;
  color: #00a1b3;
  margin: 0 6px 0 0;
  font-size: 9px;
  padding: 5px 15px;
  font-weight: 700;
}

[class*='table-type'] .proposal-status {
  height: 22px;
  border-radius: 12px;
  background: #b3b3b3;
  color: #fff;
  margin: 0 6px 0 0;
  font-size: 10px;
  padding: 6px 15px;
  font-weight: 700;
}
.proposal-status.period {
  background: #006199;
}
.proposal-status.applied {
  background: #2db350;
}
.proposal-status.disapproved {
  background: #ff1900;
}
.proposal-status.canceled {
  background: #b3b3b3;
}
.proposal-status.approved {
  background: #00b8cc;
}
.proposal-status.expired {
  background: #545454;
}
[class*='table-type'] .comment {
  line-height: 20px;
}

.table-typeA.id td:nth-child(3),
.table-typeA.id td:nth-child(4),
.table-typeA.id td:nth-child(4),
.table-typeA.id td:nth-child(5) {
  text-align: center;
}
.table-typeA.id td:nth-child(6) {
  text-align: right;
}
.table-typeA.id th:nth-child(2) {
  width: 335px;
}
.table-typeA.id th:nth-child(3) {
  width: 120px;
}
.table-typeA.id th:nth-child(4) {
  width: 85px;
}
.table-typeA.id th:nth-child(5) {
  width: 222px;
}
.table-typeA.id th:nth-child(6) {
  width: 149px;
}
.table-typeA.id td:nth-child(1) .ellipsis {
  width: 200px;
}
.table-typeA.id td:nth-child(2) .ellipsis {
  width: 295px;
}

/*status*/
.status-holder {
  position: relative;
  cursor: pointer;
}
.status-holder .img{
  width: 7px !important;
  height: 5px !important;
  background-position: 0 -50px !important;
}
.status-holder > span:hover {
  color: #1aaaba;
}
.status-holder > span.on .img,
.status-holder > span:hover .img {
  background-position: 0 -100px;
}
.status-holder ul {
  width: 88px;
  border: 1px solid #4d4d4d;
  box-shadow: 0 20px 30px 0 rgba(0, 25, 77, 0.07);
  border-radius: 6px;
  position: absolute;
  top: 32px;
  left: 26px;
  background: #fff;
  margin-left: -10px;
  overflow: hidden;
  display: none;
  font-weight: 400;
  z-index: 1;
  padding: 5px 0;
}
.status-holder ul li {
  display: block;
  cursor: pointer;
}
.status-holder ul li:hover {
  background: #e8f6f8;
  color: #1aaaba;
}
.status-holder ul li span {
  width: 68px;
  display: inline-block;
  height: 32px;
  text-align: left;
  padding-left: 10px;
  line-height: 34px;
}
.status-holder ul li:last-child span {
  border-bottom: none;
}

.table-typeB td:first-child,
.table-typeB td:nth-child(3) {
  width: 210px;
  background: #f7f7f7;
}
.table-typeB td:nth-child(4) {
  width: 357px;
}
.table-typeB {
  font-size: 12px;
}
.table-typeB thead {
  display: none;
}
.table-typeB tbody {
  text-align: center;
}
.table-typeB td {
  border-bottom: 1px solid #d9d9d9;
  padding: 10px 20px 10px;
  text-align: left;
  border-left: none;
  height: 50px;
}
.table-typeB td:first-child {
  border-bottom: 1px solid #e6e6e6;
  color: #808080;
  background: #f7f7f7;
  width: 240px;
}
.table-typeB .mint {
  color: #1aaaba;
}

.table-typeB tr .copy,
.table-typeB tr .qrcode {
  width: 26px;
  height: 26px;
  border-radius: 3px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #d9d9d9;
  background-color: #ffffff;
  vertical-align: top;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  margin-left: 4px;
}
.table-typeB tr .qrcode i.img {
  background-position: -200px 0 !important;
  width: 16px;
  height: 16px;
  margin: 5px 4px;
}
.table-typeB tr .copy:hover,
.table-typeB tr .qrcode:hover {
  border: 1px solid #1aaaba;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.05);
}
.table-typeB tr .qrcode:hover i.img {
  background-position: -250px 0 !important;
}
.table-typeB tr .copy i.img {
  background-position: -150px -400px !important;
  width: 12px;
  height: 15px;
  margin: 5px 6px;
}
.table-typeB tr .copy:hover i.img {
  background-position-y: -450px !important;
}

.table-typeB tr.governance {
}
.table-typeB tr.governance span {
  margin-right: 30px;
}
.table-typeB tr.governance em {
  font-size: 10px;
  margin-right: 6px;
  color: #4d4d4d;
}
.table-typeB tr.governance span i {
  font-style: italic;
}
.table-typeB td sub {
  font-size: 8px;
  color: #4d4d4d;
  margin: 0 6px 0 1px;
  font-weight: 400;
}
.table-typeB tr.governance sub {
  vertical-align: sub;
}

.table-typeB tr.p-rep span:not(.active):not(.btn-scam) {
  position: relative;
}
.table-typeB tr.p-rep span:first-child * {
  font-weight: 700;
}
.table-typeB tr.p-rep span:first-child em {
  font-size: 18px;
  color: #4d4d4d;
}
.table-typeB tr.p-rep span:first-child em sub {
  font-size: 12px;
  margin-right: 10px;
}
.table-typeB tr.p-rep .active {
  position: absolute;
  right: 150px;
  top: 20px;
  color: #b3b3b3;
  font-weight: 700;
}
.table-typeB tr.p-rep .active i {
  display: inline-block;
  margin-right: 4px;
  right: 150px;
  width: 9px;
  height: 9px;
  background: #b3b3b3;
  border-radius: 50%;
  vertical-align: middle;
}
.table-typeB tr.p-rep .active.on {
  color: #09a4b5;
}
.table-typeB tr.p-rep .active.on i {
  background: #09a4b5;
}
.table-typeB tr.p-rep i.img {
  width: 26px;
  height: 26px;
  top: 0;
  margin-right: 6px;
  cursor: pointer;
}
.table-typeB tr.p-rep .home i {
  background-position: 0 -550px;
  margin-left: 11px;
}
.table-typeB tr.p-rep .email i {
  background-position: -25px -550px;
}
.table-typeB tr.p-rep .twitter i {
  background-position: -50px -550px;
}
.table-typeB tr.p-rep .facebook i {
  background-position: -75px -550px;
}
.table-typeB tr.p-rep .github i {
  background-position: -100px -550px;
}
.table-typeB tr.p-rep .telegram i {
  background-position: -125px -550px;
}
.table-typeB tr.p-rep .reddit i {
  background-position: -150px -550px;
}
.table-typeB tr.p-rep .steemit i {
  background-position: -175px -550px;
}
.table-typeB tr.p-rep .youtube i {
  background-position: -200px -550px;
}
.table-typeB tr.p-rep .keybase i {
  background-position: -225px -550px;
}
.table-typeB tr.p-rep .wechat i {
  background-position: -250px -550px;
}
.table-typeB tr.p-rep i:hover {
  background-position-y: -575px;
}
.table-typeB tr.p-rep .help-layer {
  left: -10px;
  bottom: 26px;
  border-radius: 3px;
}
.table-typeB tr .small {
  font-size: 10px;
}

.table-typeB td p {
  margin: 5px 0;
}
.table-typeB td.transfer {
  padding: 2px 30px;
  text-align: center;
}
.table-typeB td.transfer p {
  margin: 0px 0;
  padding: 9px 0;
  text-align: left;
}
.table-typeB td.transfer p:not(:last-child) {
  border-bottom: 1px dotted #e6e6e6;
}
.table-typeB td.transfer .more {
  color: #4d4d4d;
  display: block;
  padding: 12px 0 8px;
}
.table-typeB td.transfer .more .img {
  width: 16px;
  height: 16px;
  background-position: -250px -300px;
  top: 4px;
  margin: 0 0 0 14px;
}
.table-typeB td.transfer .more .img.on {
  background-position: -275px -300px;
}
.table-typeB td.transfer .more:hover {
  color: #1aaaba;
  text-decoration: none;
}
.table-typeB td.transfer .more:hover .img {
  background-position: -250px -325px;
}
.table-typeB td.transfer .more:hover .img.on {
  background-position: -275px -325px;
}

.table-typeB td span {
  word-break: break-all;
}
.table-typeB td span.ellipsis {
  width: 200px;
  vertical-align: top;
}

.table-typeB a {
  font-weight: 800;
}

.table-typeB .copy.disabled {
  cursor: default;
  color: #ccc;
  border-color: #ccc;
  box-shadow: none;
}
.table-typeB .copy.twit {
  width: 26px;
  min-width: auto;
}
.table-typeB .copy.twit .img {
  background-position: -50px -550px !important;
  width: 26px;
  height: 26px;
  margin: 0;
}
.table-typeB .copy.twit:hover .img {
  background-position-y: -575px !important;
}

.table-typeB .copy.on {
  background: #1aaaba;
  color: #fff;
  border: 1px solid #1aaaba;
}
[class*='table-type'] a.download:hover {
  text-decoration: default;
  opacity: 1;
}
.table-typeB .copy.download:active {
  background: #1aaaba;
  color: #fff;
  border: 1px solid #1aaaba;
}

.table-typeB .btn-scam {
  font-size: 12px;
  color: #808080;
  height: 26px;
  border-radius: 3px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #d9d9d9;
  padding: 6px 18px;
  font-weight: 400;
  background-color: #ffffff;
  display: inline-block;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
.table-typeB .btn-scam:hover {
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.05);
  text-decoration: none;
}

.table-typeB .btn-scam.complete {
  font-size: 12px;
  color: #4d4d4d !important;
  border: 1px solid #262626;
}
.table-typeB .btn-scam.complete:hover {
  color: #1aaaba !important;
  border: 1px solid #1aaaba;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.05);
  text-decoration: none;
}

.table-typeB .link:hover {
  text-decoration: underline;
  cursor: pointer;
}
.table-typeB .help {
  position: absolute;
  height: 26px;
  border-radius: 26px;
  background: rgba(38, 38, 38, 0.9);
  color: #fff;
  font-size: 12px;
  line-height: 28px;
  padding: 0 16px;
  font-weight: 500;
  pointer-events: none;
}
.table-typeB .help.token {
  top: -14px;
  left: 10px;
}
.table-typeB .help.address {
  top: -14px;
  left: 10px;
}
.table-typeB .help.hash {
  top: -14px;
  left: 180px;
}
.table-typeB .link {
  width: 120px !important;
  vertical-align: top;
  color: #1aaaba;
}
.table-typeB .link.address {
  margin-right: 10px;
}
.table-typeB .link.hash {
  margin-left: 10px;
}
.table-typeB .qr {
  padding: 10px 40px;
}
.table-typeB .qr .img {
  vertical-align: top;
}

.table-typeB .scroll {
  height: auto !important; /*max-height: 300px;*/
  overflow-y: auto;
}
.table-typeB .scroll span {
  color: #4caf50;
  pointer-events: none;
}
.table-typeB .scroll p {
  line-height: 24px;
  word-break: break-all;
  white-space: pre;
  width: 850px;
}
.table-typeB .convert {
  padding: 14px 30px;
}
.table-typeB .convert button {
  margin-top: 10px;
}
.table-typeB .fail {
  color: #e6001b;
  /* pointer-events: none; */
}
.table-typeB .fail em {
  color: #e6001b;
}
.table-typeB .fail span {
  color: #e6001b;
}

.table-typeB .combo-layer .scroll {
  max-height: 340px;
  height: auto;
}

.table-typeB .combo-group {
  position: relative;
  display: none;
}
.table-typeB .combo-layer {
  width: 464px;
  box-shadow: 0 4px 15px 0 rgba(38, 38, 38, 0.2);
  background: #ffffff;
  border: solid 2px #262626;
  position: absolute;
  z-index: 1;
  top: -6px;
  margin-left: -20px;
  border-radius: 6px;
  box-shadow: 0 20px 30px 0 rgba(0, 25, 77, 0.07);
}

.table-typeB td.balance {
  padding: 0 20px 10px;
}
.table-typeB td.balance p {
  position: relative;
  margin: 0;
}
.table-typeB td.balance p:first-child {
  height: 50px;
  margin: 0;
  padding-top: 15px;
}
.table-typeB td.balance p:first-child > em {
  position: absolute;
  right: 0;
  cursor: pointer;
  width: 20px;
  height: 20px;
}
.table-typeB td.balance p:first-child > em i.img {
  width: 7px;
  height: 5px;
  background-position: 0px -50px;
  left: 6px;
  top: 4px;
  margin: 0;
}
.table-typeB td.balance p:first-child span:nth-of-type(2) {
  margin-left: 18px;
}
.table-typeB td.balance p:not(:first-child) {
  padding: 8px 0 8px 26px;
}
.table-typeB td.balance p:not(:first-child) span:first-child {
  font-weight: 400;
}

.table-typeB td.balance p span:first-child {
  width: 240px;
  display: inline-block;
}
.table-typeB td.balance p span:nth-of-type(2) {
  width: 240px;
  display: inline-block;
  text-align: right;
  position: relative;
}
.table-typeB td.balance p span:nth-of-type(2) em {
  position: relative;
  margin-right: 10px;
  font-size: 10px;
  font-weight: 300;
}
.table-typeB td.balance p span:nth-of-type(2) em:last-child {
  position: absolute;
  right: -128px;
  text-align: left;
  width: 110px;
  font-size: 12px;
}
.table-typeB td.balance span {
  cursor: default;
  color: #4d4d4d;
  pointer-events: none;
}
.table-typeB td.balance div {
  position: relative;
  overflow: hidden;
  height: 50px;
}
.table-typeB td.balance div:first-child {
  padding-top: 0;
}
.table-typeB td.balance div:last-child {
  padding-bottom: 0;
}
.table-typeB td.balance div:first-child:after {
  content: '';
  border-bottom: 1px dotted #d9d9d9;
  position: absolute;
  bottom: 0;
  width: calc(100% + 40px);
  left: -20px;
}
.table-typeB td.balance div:not(:last-child) p:last-child {
  padding-bottom: 20px;
}
.table-typeB td.balance div.on {
  height: auto;
}
.table-typeB td.balance div.on p:first-child > em i.img {
  transform: rotate(180deg);
}

.table-typeB td.balance .coin {
  vertical-align: text-bottom;
  margin-right: 8px;
  width: 18px;
}
.table-typeB td.balance .coin.icon {
  width: 18px;
  height: 18px;
}
.table-typeB td.balance .coin.bitcoin-n {
  width: 26px;
  height: 18px;
  background-position: -25px -25px;
}

.table-typeB .combo-layer .search-group {
  top: 0px;
  margin: 20px;
  float: none;
  color: #4d4d4d;
  width: auto;
}
.table-typeB .combo-layer .search-group input {
  height: 26px;
  padding: 10px;
  font-size: 12px;
  width: 420px;
  border-radius: 2px;
}
.table-typeB .combo-layer .list-group {
  font-size: 12px;
  font-weight: 500;
}
.table-typeB .combo-layer .list-group li {
  display: block;
  cursor: pointer;
  padding: 14px 0;
  position: relative;
}
.table-typeB .combo-layer .list-group li:hover {
  background: #f7f8fa;
}
.table-typeB .combo-layer .list-group li p {
  margin: 0 20px;
  width: auto;
}

.table-typeB .combo-layer .list-group li:first-child p {
  border-top: none;
}
.table-typeB .combo-layer .list-group li p em {
  color: #4d4d4d;
}
.table-typeB .combo-layer .list-group li p em:nth-child(1) {
  width: 260px;
  font-weight: 600;
  margin-left: 10px;
}
.table-typeB .combo-layer .list-group li p em:nth-child(2) {
  width: 106px;
  text-align: right;
}
.table-typeB .combo-layer .list-group li p em:nth-child(3) {
  width: 24px;
  color: #b3b3b3;
  text-align: right;
}
.table-typeB .combo-layer .list-group li::after {
  content: '';
  display: block;
  height: 1px;
  background: #e6e6e6;
  position: absolute;
  width: 420px;
  left: 20px;
  bottom: 0;
}
.table-typeB .combo-layer .list-group li p:last-child em {
  color: #b3b3b3;
  font-weight: 400;
}

.table-typeB .combo-layer.off {
  border: 2px solid transparent;
  box-shadow: none;
}
.table-typeB .combo-layer.off .list-group {
  display: none;
}
.table-typeB .combo-layer.off .search-group {
  display: none;
}

.table-typeB .combo-layer .nodata {
  padding: 0px 30px 18px;
}
.table-typeB .combo-layer input:-ms-input-placeholder {
  color: #b3b3b3;
}
.table-typeB .combo-layer input::-webkit-input-placeholder {
  color: #b3b3b3;
}
.table-typeB .combo-layer input::-moz-placeholder {
  color: #b3b3b3;
}
.table-typeB .combo-layer input::-moz-placeholder {
  color: #b3b3b3;
}

.table-typeB .gray {
  color: #b3b3b3;
  margin-left: 6px;
  font-weight: 400;
  cursor: default;
}
.table-typeB .gray:hover {
  text-decoration: none;
}
.table-typeB.address tr:nth-child(2) .img {
  background-position: -150px 0;
  width: 14px;
  height: 14px;
  margin: 0 10px 0 20px;
  vertical-align: baseline;
  cursor: default;
}

.table-typeB.detail td .next,
.table-typeB.detail td .prev {
  display: inline-block;
  width: 26px;
  height: 26px;
  text-align: center;
  padding-top: 6px;
  cursor: pointer;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
}
.table-typeB.detail td .next em,
.table-typeB.detail td .prev em {
  width: 6px;
  height: 8px;
  margin-left: 0px;
}
.table-typeB.detail td .next .img {
  background-position: -150px -50px;
}
.table-typeB.detail td .next:hover .img {
  background-position: -150px -100px;
}
.table-typeB.detail td .prev .img {
  background-position: -100px -50px;
}
.table-typeB.detail td .prev:hover .img {
  background-position: -100px -100px;
}
.table-typeB.detail tr {
  vertical-align: middle;
}

.table-typeB.detail td p.disabled {
  border-color: #ccc;
  cursor: default;
  box-shadow: none;
}
.table-typeB.detail td p.disabled:hover {
  border-color: #ccc;
}
.table-typeB.detail td p.disabled.prev .img {
  background-position: -100px -300px;
}
.table-typeB.detail td p.disabled.prev:hover .img {
  background-position: -100px -300px;
}
.table-typeB.detail td p.disabled.next .img {
  background-position: -150px -300px;
}
.table-typeB.detail td p.disabled.next:hover .img {
  background-position: -150px -300px;
}

.table-typeB.detail .value {
  display: inline-block;
  width: 200px;
  text-align: center;
  font-weight: 800;
  color: #4d4d4d;
}
.table-typeB.detail p:hover {
  border: 1px solid #1aaaba;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.05);
}
.table-typeB.detail .message {
  padding: 14px 30px;
}
.table-typeB.detail .message p {
  word-break: break-all;
  line-height: 18px;
}
.table-typeB.detail .message p:hover {
  border: none;
  box-shadow: none;
}

.table-typeB.contract td:first-child,
.table-typeB.contract td:nth-child(3) {
  width: 210px;
  background: #f0f2f5;
}
.table-typeB.contract em {
  color: #4d4d4d;
}
.table-typeB.contract .status {
  position: absolute;
  right: 20px;
  top: 12px;
  font-weight: 400;
}

.table-typeB tr i.img {
  width: 18px;
  height: 22px;
  background-position: 0 -254px;
  vertical-align: middle;
  margin-right: 10px;
  position: relative;
}
.table-typeB.transaction tr i.img {
  width: 12px;
  background-position: -50px 4px;
}
.table-typeB.transaction span.copy:nth-of-type(1) {
  margin-left: 20px;
}
.table-typeB.transaction tr .trans {
  padding: 8px 20px 2px;
}
.table-typeB.transaction tr .trans p {
  font-size: 10px;
  font-weight: 400;
  margin: 0px 0;
  padding: 9px 0;
  text-align: left;
}
.table-typeB.transaction tr .trans div {
  margin-top: 6px;
  text-align: center;
}
.table-typeB.transaction tr .trans.calen .img {
  background-position: 0px -248px;
  width: 12px;
}

.table-typeB.transaction tr .trans .more {
  color: #4d4d4d;
  display: block;
  padding: 12px 0 8px;
}
.table-typeB.transaction tr .trans .more .img {
  width: 16px;
  height: 16px;
  background-position: -250px -300px;
  top: 4px;
  margin: 0 0 0 14px;
}
.table-typeB.transaction tr .trans .more .img.on {
  background-position: -275px -300px;
}
.table-typeB.transaction tr .trans .more:hover {
  color: #1aaaba;
  text-decoration: none;
}
.table-typeB.transaction tr .trans .more:hover .img {
  background-position: -250px -325px;
}
.table-typeB.transaction tr .trans .more:hover .img.on {
  background-position: -275px -325px;
}
.table-typeB.transaction tr .trans p:not(:last-child) {
  border-bottom: 1px dotted #e6e6e6;
}
.table-typeB.transaction tr em em {
  color: #4d4d4d;
  margin: 0 6px 0 0;
}
.table-typeB.transaction tr .id div {
  margin: -10px 0;
}
.table-typeB.transaction tr .id p:not(:first-child):after {
  content: '';
  position: absolute;
  border-bottom: 1px dotted #d9d9d9;
  width: calc(100% + 40px);
  left: -20px;
  top: 0;
}
.table-typeB.transaction tr .id p {
  font-size: 12px;
  margin: 0px 0;
  padding: 13px 0;
  text-align: left;
  height: 40px;
  position: relative;
}
[class*='table-type'] .scam .img {
  background-position: -125px 0px !important;
  width: 12px;
  top: -1px;
  margin-right: 4px;
  position: relative;
  vertical-align: middle;
  height: 12px;
}
.table-typeB .scam span:not(.copy) {
  /*color:#f24130;*/
}
.table-typeB em.scam-tag {
  background: #f83725;
  height: 18px;
  border-radius: 9px;
  color: #fff !important;
  font-size: 9px;
  padding: 5px 13px;
  display: inline-block;
  cursor: default;
  font-weight: 400;
  margin-right: 6px;
}
.table-typeB .trans span.scam .img {
  width: 10px;
  height: 10px;
  background-position: -125px -23px !important;
  margin-right: 2px;
}

.table-typeC td:nth-child(2) {
  text-align: center;
}
.table-typeC td:nth-child(3) {
  text-align: center;
}
.table-typeC td:nth-child(7) {
  text-align: right;
}
.table-typeC td:nth-child(8) {
  text-align: right;
}
.table-typeC th:nth-child(1) {
  width: 204px;
}
.table-typeC th:nth-child(2) {
  width: 95px;
}
.table-typeC th:nth-child(3) {
  width: 200px;
}
.table-typeC th:nth-child(7) {
  width: 166px;
}
.table-typeC th:nth-child(8) {
  width: 150px;
}
.table-typeC th:nth-child(9) {
  width: 150px;
}
.table-typeC td:nth-child(1) .ellipsis {
  width: 163px;
}
.table-typeC td:nth-child(1).icon .ellipsis {
  width: 148px;
}
.table-typeC td:not(:nth-child(1)).icon .ellipsis {
  width: 100px;
}
.table-typeC td {
  padding: 11px 19px 5px;
}
.table-typeC .ellipsis {
  width: 110px;
}
.table-typeC td:nth-child(1) {
}
.table-typeC sup {
  font-size: 8px;
  font-weight: 700;
  vertical-align: super;
}

.table-typeC.token td:nth-child(2) {
  text-align: right;
}
.table-typeC.token td:nth-child(6) {
  text-align: right;
}
.table-typeC.token td:nth-child(7) {
  text-align: left;
}

.table-typeC.token th:nth-child(1) {
  width: 150px;
}
.table-typeC.token th:nth-child(2) {
  width: 200px;
}
.table-typeC.token th:nth-child(3) {
  width: auto;
}
.table-typeC.token th:nth-child(6) {
  width: 266px;
}
.table-typeC.token th:nth-child(7) {
  width: 199px;
}

.table-typeC.token td:nth-child(1) .ellipsis {
  width: 114px;
}
.table-typeC.token td:not(:nth-child(1)) .ellipsis {
  width: 110px;
}
.table-typeC.token td:nth-child(1).icon .ellipsis {
  width: 100px;
}
.table-typeC.token td:not(:nth-child(1)).icon .ellipsis {
  width: 100px;
}
.table-typeC.token td:nth-child(6) .ellipsis {
  width: 51px;
  vertical-align: top;
}
.table-typeC.token td:nth-child(7) span.ellipsis {
  vertical-align: top;
  width: 160px;
  font-weight: 400;
}
.table-typeC.token.tap2 td:nth-child(2) {
  text-align: right;
}

.table-typeC.internal td:nth-child(3) {
  text-align: center;
}
.table-typeC.internal td:nth-child(7) {
  text-align: right;
}
.table-typeC.internal th:nth-child(1) {
  width: 350px;
}
.table-typeC.internal th:nth-child(2) {
  width: 95px;
}
.table-typeC.internal th:nth-child(3) {
  width: 200px;
}
.table-typeC.internal th:nth-child(4) {
  width: auto;
}
.table-typeC.internal th:nth-child(7) {
  width: 170px;
}
.table-typeC.internal td:nth-child(1) .ellipsis {
  width: 310px;
}
.table-typeC.internal td:not(:nth-child(1)) .ellipsis {
  width: 120px;
}
.table-typeC.internal td:nth-child(1).icon .ellipsis {
  width: 295px;
}
.table-typeC.internal td:not(:nth-child(1)).icon .ellipsis {
  width: 110px;
}

.table-typeC.txns td:nth-child(1),
.table-typeC.txns td:nth-child(4) {
  text-align: center;
}
.table-typeC.txns td:nth-child(2) {
  text-align: right;
}
.table-typeC.txns td:nth-child(7) {
  text-align: left;
}
.table-typeC.txns td:last-child {
  text-align: right;
}
.table-typeC.txns th:nth-child(1) {
  width: 120px;
}
.table-typeC.txns th:nth-child(2) {
  width: 222px;
}
.table-typeC.txns th:nth-child(3) {
  width: 220px;
}
.table-typeC.txns th:nth-child(4) {
  width: 80px;
}
.table-typeC.txns th:nth-child(5) {
  width: auto;
}
.table-typeC.txns th:nth-child(8) {
  width: 166px;
}
.table-typeC.txns td.age {
  width: 150px;
  text-align: right;
}
.table-typeC.txns td.age + td {
  text-align: left;
}
.table-typeC.txns td.age + td + td {
  text-align: center;
}
.table-typeC.txns td.age + td + td + td {
  text-align: left;
}
.table-typeC.txns td .ellipsis:nth-child(-n + 2) {
  width: 180px;
}
.table-typeC.txns td:not(:nth-child(-n + 3)) .ellipsis {
  width: 110px;
}
.table-typeC.txns td.icon .ellipsis:nth-child(-n + 2) {
  width: 165px;
}
.table-typeC.txns td:not(:nth-child(-n + 3)).icon .ellipsis {
  width: 100px;
}

.table-typeC.it td:nth-child(5) {
  text-align: right;
}
.table-typeC.it td:nth-child(6) {
  text-align: right;
}
.table-typeC.it th:nth-child(1) {
  width: 500px;
}
.table-typeC.it th:nth-child(2) {
  width: auto;
}
.table-typeC.it th:nth-child(5) {
  width: 166px;
}
.table-typeC.it th:nth-child(6) {
  width: 150px;
}
.table-typeC.it td:not(:nth-child(1)) .ellipsis {
  width: 110px;
}
.table-typeC.it td:not(:nth-child(1)).icon .ellipsis {
  width: 100px;
}
.table-typeC.it .parent {
  border-bottom: none;
}

.table-typeC.voter {
}
.table-typeC.voter th:nth-child(1) {
  width: auto;
}
.table-typeC.voter th:nth-child(2) {
  width: 300px;
}
.table-typeC.voter td:nth-child(1) {
  color: #4d4d4d;
  text-align: left;
  font-weight: 700;
}
.table-typeC.voter td:nth-child(2) {
  color: #4d4d4d;
  text-align: right;
}
.table-typeC.voter td:nth-child(2).plus * {
  color: #1aaaba;
}
.table-typeC.voter td .ellipsis {
  width: 660px;
  color: #1aaaba;
}
.table-typeC.voter td .prep-tag {
  padding: 4px 15px;
  vertical-align: top;
}

.table-typeC.proposal {
}
.table-typeC.proposal th:nth-child(1) {
  width: auto;
}
.table-typeC.proposal th:nth-child(2) {
  min-width: 140px;
}
.table-typeC.proposal th:nth-child(3) {
  width: 500px;
}
.table-typeC.proposal th:nth-child(4) {
  width: 200px;
}
.table-typeC.proposal td:nth-child(1) {
  text-align: left;
}
.table-typeC.proposal td:nth-child(4) {
  text-align: center;
}
.table-typeC.proposal td.on {
  color: #1aaaba !important;
}
.table-typeC.proposal td .ellipsis {
  width: 460px;
  color: #1aaaba;
}

.table-typeC.proposal.votes th:nth-child(1) {
  width: auto;
}
.table-typeC.proposal.votes th:nth-child(2) {
  min-width: 170px;
}
.table-typeC.proposal.votes th:nth-child(3) {
  width: 470px;
}
.table-typeC.proposal.votes th:nth-child(4) {
  width: 200px;
}
.table-typeC.proposal.votes td:nth-child(2) {
  text-align: right;
}
.table-typeC.proposal.votes td .ellipsis {
  width: 430px;
  color: #1aaaba;
}

.table-typeC.reward th:nth-child(1) {
  width: 152px;
}
.table-typeC.reward th:nth-child(2) {
  width: 95px;
}
.table-typeC.reward th:nth-child(3) {
  width: 200px;
}
.table-typeC.reward th:nth-child(7) {
  width: 220px;
}
.table-typeC.reward th:nth-child(8) {
  width: 150px;
}
.table-typeC.reward td:nth-child(1) .ellipsis {
  width: 112px;
}
.table-typeC.reward td:nth-child(1).icon .ellipsis {
  width: 98px;
}

.table-typeD {
}
.notrans {
  text-align: center;
  vertical-align: middle;
  height: 78px;
  font-size: 12px;
  background: #f5f5f5;
}
.table-typeD td:nth-child(5) {
  text-align: right;
}
.table-typeD td:nth-child(6) {
  text-align: right;
}
.table-typeD th:nth-child(1) {
  width: 500px;
}
.table-typeD th:nth-child(5) {
  width: 166px;
}
.table-typeD th:nth-child(6) {
  width: 150px;
}
.table-typeD td:nth-child(1) .ellipsis {
  width: 450px;
}
.table-typeD td:not(:nth-child(1)) .ellipsis {
  width: 110px;
}
.table-typeD td:nth-child(1).icon .ellipsis {
  width: 440px;
}
.table-typeD td:not(:nth-child(1)).icon .ellipsis {
  width: 100px;
}

.table-typeE td {
  height: 34px;
}
.table-typeE td:nth-child(1) {
  text-align: center;
}
.table-typeE td:nth-child(2) {
  text-align: center;
}
.table-typeE td:nth-child(3) {
  text-align: right;
}
.table-typeE td:nth-child(4) {
  text-align: right;
}
.table-typeE td:nth-child(5) {
  text-align: right;
}
.table-typeE td:nth-child(6) {
}
.table-typeE th:nth-child(1) {
  width: 95px;
}
.table-typeE th:nth-child(2) {
  width: 200px;
}
.table-typeE th:nth-child(3) {
  width: 150px;
}
.table-typeE th:nth-child(4) {
  width: 124px;
}
.table-typeE th:nth-child(5) {
  width: 150px;
}
.table-typeE td:nth-child(6) .ellipsis {
  width: 375px;
}

/*.table-typeE td:not(:nth-child(1)).icon .ellipsis{width:375px; }*/

.table-typeF td:nth-child(2) {
  text-align: center;
}
.table-typeF td:nth-child(6) {
  text-align: right;
}
.table-typeF td:nth-child(7) {
  text-align: right;
}
.table-typeF th:nth-child(1) {
  width: 210px;
}
.table-typeF th:nth-child(2) {
  width: 222px;
}
.table-typeF th:nth-child(6) {
  width: 190px;
}
.table-typeF th:nth-child(7) {
  width: 130px;
}
.table-typeF td:nth-child(1) .ellipsis {
  width: 167px;
}
.table-typeF td:not(:nth-child(1)) .ellipsis {
  width: 110px;
}
.table-typeF td:nth-child(1).icon .ellipsis {
  width: 152px;
}
.table-typeF td:not(:nth-child(1)).icon .ellipsis {
  width: 100px;
}
.table-typeF.token th:nth-child(6) {
  width: 257px;
}
.table-typeF.token td:nth-child(6) .ellipsis {
  width: 51px;
  vertical-align: top;
}

.table-typeM td:nth-child(1) {
  text-align: center;
}
.table-typeM th:nth-child(1) {
  width: 84px;
}
.table-typeM th:nth-child(3) {
  width: 220px;
}
.table-typeM th:nth-child(4) {
  width: 220px;
}
.table-typeM td:nth-child(3),
.table-typeM td:nth-child(4) {
  text-align: right;
}
.table-typeM .ellipsis {
  width: 600px;
}
.table-typeM td:nth-child(3) .ellipsis {
  width: 51px;
  vertical-align: top;
}

.table-typeG td:nth-child(1) {
  text-align: center;
}
.table-typeG td:nth-child(3) {
  text-align: right;
}
.table-typeG td:nth-child(4) {
  text-align: right;
}
.table-typeG th:nth-child(1) {
  width: 84px;
}
.table-typeG th:nth-child(3) {
  width: 220px;
}
.table-typeG th:nth-child(4) {
  width: 180px;
}
.table-typeG .ellipsis {
  width: 600px;
}
.table-typeG td:nth-child(3) .ellipsis {
  width: 51px;
  vertical-align: top;
}

.table-typeH td:nth-child(1) p:last-child {
  color: #4d4d4d;
  margin-top: 2px;
}
.table-typeH td:nth-child(2) {
  text-align: center;
}
.table-typeH td:nth-child(3) {
  line-height: 20px;
}
.table-typeH td:nth-child(3) span {
  color: #4caf50;
}
.table-typeH th:nth-child(1) {
  width: 234px;
}
.table-typeH th:nth-child(2) {
  width: 234px;
}
.table-typeH .ellipsis {
  width: 184px;
}

.table-typeH.log th {
  padding: 14px 20px;
  text-align: left;
  font-family: 'Montserrat';
}
.table-typeH.log td {
  line-height: 20px;
}
.table-typeH.log td span {
  color: #e6001b;
}

.table-typeI td:nth-child(1),
.table-typeI td:nth-child(4) {
  text-align: center;
}
.table-typeI td:nth-child(3) {
  text-transform: uppercase;
}
.table-typeI td:nth-child(3),
.table-typeI td:nth-child(5),
.table-typeI td:nth-child(6) {
  text-align: center;
}

.table-typeI tbody {
  font-size: 12px;
}
.table-typeI th:nth-child(1) {
  width: 84px;
}
.table-typeI th:nth-child(2) {
  color: #b3b3b3;
}
.table-typeI th:nth-child(3) {
  width: 170px;
  color: #b3b3b3;
}
.table-typeI th:nth-child(4) {
  width: 124px;
  color: #b3b3b3;
}
.table-typeI th:nth-child(5) {
  width: 200px;
  color: #b3b3b3;
}
.table-typeI th:nth-child(6) {
  width: 200px;
  color: #b3b3b3;
}
.table-typeI thead th .img {
  width: 6px;
  height: 8px;
  background-position: -100px -325px;
}
.table-typeI thead th.marketcap {
  cursor: pointer;
}
.table-typeI thead th.marketcap:hover {
  color: #1aaaba;
}
.table-typeI thead th em {
  margin-right: 6px;
}
.table-typeI td:nth-child(1) {
  font-size: 16px;
  font-weight: 700;
}
.table-typeI td:nth-child(2) {
  color: #1aaaba;
}
.table-typeI td:nth-child(3) {
  font-size: 10px;
}
.table-typeI td:nth-child(3) em {
  width: 30px;
  color: #b3b3b3;
}
.table-typeI td:nth-child(5) em,
.table-typeI td:nth-child(6) em {
  color: #b3b3b3;
}
.table-typeI i {
  width: 30px;
  height: 30px;
  margin-right: 4px;
  display: inline-block;
  vertical-align: middle;
}
.table-typeI i img {
  width: 100%;
}
.table-typeI tr i.img {
  width: 16px;
  height: 16px;
  background-position: -200px -350px;
  cursor: pointer;
  position: absolute;
  right: 20px;
  /* top: 24px; */
}
.table-typeI td:nth-child(2) .ellipsis {
  width: 300px;
  font-weight: 700;
}

.table-typeJ td:nth-child(2),
.table-typeJ td:nth-child(3) {
  text-align: right;
}
.table-typeJ td:nth-child(7) {
  text-align: right;
}
.table-typeJ td:nth-child(8) {
  text-align: right;
}
.table-typeJ th:nth-child(1) {
  width: 190px;
}
.table-typeJ th:nth-child(2) {
  width: 85px;
}
.table-typeJ th:nth-child(3) {
  width: 222px;
}
.table-typeJ th:nth-child(7) {
  width: 166px;
}
.table-typeJ th:nth-child(8) {
  width: 150px;
}
.table-typeJ td:nth-child(9) {
  min-width: 120px ;
  max-width: 120px; 
  width: 120px;
}
.table-typeJ td:nth-child(1) .ellipsis {
  width: 155px;
}
.table-typeJ td:not(:nth-child(1)) .ellipsis {
  width: 110px;
}
.table-typeJ td:nth-child(1).icon .ellipsis {
  width: 140px;
}
.table-typeJ td:not(:nth-child(1)).icon .ellipsis {
  width: 100px;
}

.table-typeK td:nth-child(5),
.table-typeK td:nth-child(6) {
  text-align: right;
}

.table-typeL th {
  width: 33.33%;
}
.table-typeL td {
  text-align: center;
  height: 34px;
  padding: 8px 20px 8px;
}
.table-typeL td span.link {
  color: #1aaaba;
  cursor: pointer;
}
.table-typeL td span.link:hover {
  text-decoration: underline;
}
.table-typeL tr i.img {
  width: 13px;
  height: 12px;
  display: inline-block;
  background-position: -50px -250px;
  position: relative;
  margin-right: 5px;
  top: 2px;
}

.table-typeN td:nth-child(2) {
  text-align: center;
}
.table-typeN td:nth-child(6) {
  text-align: right;
}
.table-typeN td:nth-child(8) {
  text-align: right;
}
.table-typeN td:nth-child(7) {
  color: #1aaaba;
  font-weight: 700;
}
.table-typeN td:nth-child(2) {
  padding: 11px 20px 5px 10px;
}
.table-typeN td:nth-child(6) {
  padding: 11px 15px 5px;
}

.table-typeN th:nth-child(1) {
  width: 122px;
}
.table-typeN th:nth-child(2) {
  width: 222px;
}
.table-typeN th:nth-child(6) {
  width: 255px;
}
.table-typeN th:nth-child(7) {
  width: 72px;
}
.table-typeN th:nth-child(8) {
  width: 110px;
}
.table-typeN td:nth-child(1) .ellipsis {
  width: 82px;
}
.table-typeN td:not(:nth-child(1)) .ellipsis {
  width: 110px;
}
.table-typeN td:nth-child(1).icon .ellipsis {
  width: 66px;
}
.table-typeN td:not(:nth-child(1)).icon .ellipsis {
  width: 100px;
}
.table-typeN td:last-child .ellipsis {
  width: 210px;
  font-weight: 700;
}
.table-typeN td:nth-child(6) .ellipsis {
  width: 51px;
  vertical-align: top;
}
.table-typeN td:nth-child(7) .ellipsis {
  width: 90px;
}

.table-typeK td:nth-child(1),
.table-typeK td:nth-child(2),
.table-typeK td:nth-child(4) {
  text-align: center;
}
.table-typeK th:nth-child(1) {
  width: 84px;
}
.table-typeK th:nth-child(2) {
  width: 150px;
}
.table-typeK th:nth-child(4) {
  width: 194px;
}
.table-typeK td:nth-child(3) .ellipsis {
  width: 600px;
}
.table-typeK td:not(:nth-child(3)) .ellipsis {
  width: 510px;
}
.table-typeK td:nth-child(3).icon .ellipsis {
  width: 590px;
}
.table-typeK td:not(:nth-child(3)).icon .ellipsis {
  width: 500px;
}

.table-typeO .table-sign + td {
  width: auto;
}
.table-typeO td:nth-child(1),
.table-typeO td:nth-child(2) {
  text-align: center;
}
.table-typeO td:nth-child(6),
.table-typeO td:nth-child(7) {
  text-align: right;
}
.table-typeO th:nth-child(1) {
  width: 200px;
}
.table-typeO th:nth-child(2) {
  width: 120px;
}
.table-typeO th:nth-child(3) {
  width: 120px;
}
.table-typeO th:nth-child(4) {
  width: 85px;
}
.table-typeO th:nth-child(6) {
  width: 166px;
}
.table-typeO th:nth-child(7) {
  width: 149px;
}
.table-typeO th:nth-child(8) {
  width: 239px;
}
.table-typeO td:nth-child(8) .ellipsis {
  width: 190px;
}
.table-typeO td:not(:nth-child(8)) .ellipsis {
  width: 84px;
}
.table-typeO td:nth-child(8).icon .ellipsis {
  width: 180px;
}
.table-typeO td:not(:nth-child(8)).icon .ellipsis {
  width: 70px;
}

table.table-typeP th em {
  font-size: 10px;
  display: block;
  color: #808080;
  margin: 0;
}
table.table-typeP th sub {
  font-size: 8px;
  margin: 5px 0 0 2px;
  display: inline-block;
  vertical-align: top;
}
/* table.table-typeP th .itag em{font-size: 10px; display: contents; color: #808080; margin: 0;}
table.table-typeP th .itag i{width:14px; height: 14px; background-position: -150px -250px; margin-left: 3px; cursor: pointer;     vertical-align: top; position: relative; left: 0; top: 0;} */

.table-typeP col.add {
  width: 60px;
}
.table-typeP col.rank {
  width: 68px;
}
.table-typeP col.rank + col {
  width: 504px;
}
.table-typeP col.rank + col + col {
  width: 138px;
}
.table-typeP col.rank + col + col + col {
  width: 96px;
}
.table-typeP col.rank + col + col + col + col {
  width: 108px;
}
.table-typeP col.rank + col + col + col + col + col {
  width: 119px;
}
.table-typeP td {
  padding: 12px 14px 10px;
}

.table-typeP td.rank {
  font-size: 16px;
  font-weight: 700;
  text-align: center;
}
.table-typeP td.rank + td {
}
.table-typeP td.rank + td + td {
  text-align: center;
}
.table-typeP td.rank + td + td + td {
  text-align: center;
}
.table-typeP td.rank + td + td + td + td {
  text-align: center;
}
.table-typeP td.rank + td + td + td + td em {
  font-size: 11px;
  margin: 0;
  display: block;
  text-align: center;
}
.table-typeP td.rank + td + td + td + td + td {
  text-align: center;
}
.table-typeP td.rank + td + td + td + td + td + td {
  text-align: right;
}
.table-typeP td.rank + td + td + td + td + td + td + td {
  text-align: right;
}
.table-typeP td.rank + td + td + td + td + td + td + td + td {
  text-align: right;
}

.table-typeP td.rank + td + td + td + td + td + td + td em {
  font-size: 11px;
  margin: 0;
  display: block;
  text-align: right;
}
.table-typeP td.rank + td + td + td + td + td + td + td + td em {
  font-size: 11px;
  margin: 0;
  display: block;
  text-align: right;
}

.table-typeP li {
  vertical-align: middle;
}
.table-typeP li em {
  display: block;
  margin: 0;
  font-size: 10px;
  color: #1aaaba;
}
.table-typeP .prep-tag {
  height: 18px;
  border-radius: 10px;
  background: #e6f8fa;
  font-size: 9px;
  color: #00a1b3;
  padding: 4px 10px 4px 6px;
  display: inline-block;
}
.table-typeP .prep-tag i {
  width: 6px;
  height: 6px;
  background: #1aaaba;
  border-radius: 50%;
  display: inline-block;
  margin-right: 6px;
}
.table-typeP .prep-tag.off {
  border: 1px solid #d8edf0;
  background: #fff;
}
.table-typeP .prep-tag.block-synced {
  border: 1px solid #ececec;
  background: #ececec;
}
.table-typeP .prep-tag.off i {
  background: #fff;
  border: 1px solid #ececec;
}
.table-typeP .ellipsis {
  width: 380px;
}
.table-typeP .black .prep-tag {
  background: #ffedeb;
  color: #f24130;
  padding-left: 10px;
}
.table-typeP .black em {
  color: #f24130;
}
.table-typeP .add {
  display: none;
}
.governance.on .table-typeP colgroup .add {
  display: table-column;
}
.governance.on .table-typeP thead .add,
.governance.on .table-typeP tbody .add {
  display: table-cell;
}
.table-typeP .italic {
  font-style: italic;
}
.table-typeP .italic em {
  font-weight: 700;
}
.table-typeP .italic sub {
  font-weight: 400;
}

.table-typeP.vote th {
  height: 60px;
  border-top: 1px solid #d9d9d9;
  padding: 0 20px;
}
.table-typeP.vote col:nth-child(1) {
  width: 60px;
}
.table-typeP.vote col:nth-child(2) {
  width: 70px;
}
.table-typeP.vote col:nth-child(3) {
  width: 332px;
}
.table-typeP.vote col:nth-child(4) {
  width: 150px;
}
.table-typeP.vote col:nth-child(5) {
  width: 250px;
}
.table-typeP.vote col:nth-child(6) {
  width: auto;
}
.table-typeP.vote tr:last-child {
  border-bottom: 1px solid #d9d9d9;
}

.table-typeP.vote td .value input {
  width: 200px;
  padding: 12px 37px 12px 1px;
  text-align: right;
}
.table-typeP.vote td .percent input {
  width: 108px;
  padding: 12px 30px 12px 1px;
  text-align: right;
}
.table-typeP.vote td:nth-child(2) {
  font-size: 16px;
  font-weight: 700;
  text-align: center;
}
.table-typeP.vote td:nth-child(3) {
  text-align: left;
}
.table-typeP.vote td:nth-child(4) {
  text-align: center;
}
.table-typeP.vote td:nth-child(4) em {
  font-size: 11px;
  margin: 0;
  display: block;
}
.table-typeP.vote td:nth-child(5) {
  text-align: right;
}
.table-typeP.vote td:nth-child(5) em {
  font-size: 11px;
  margin: 0;
  display: block;
  text-align: right;
}
.table-typeP.vote td:nth-child(5) em i {
  color: #4d4d4d;
}
.table-typeP.vote td:nth-child(6) span {
  display: inline-block;
  position: relative;
}
.table-typeP.vote td:nth-child(6) span em {
  position: absolute;
  top: 8px;
  right: 10px;
}
.table-typeP.vote .ellipsis {
  vertical-align: middle;
  width: 200px;
}
[class*='table-type'].table-typeP td:nth-child(1) .img {
  width: 22px;
  height: 22px;
  left: 20px;
  top: 16px;
  cursor: pointer;
}
[class*='table-type'].table-typeP .plus {
  background-position: -200px -450px;
}
[class*='table-type'].table-typeP .minus {
  background-position: -200px -475px;
}
[class*='table-type'].table-typeP td:nth-child(1) .img:hover {
  background-position-x: -250px;
}
.table-typeP.vote th:nth-child(6) {
  text-align: left;
  position: relative;
}
.table-typeP.vote th:nth-child(6) em {
  position: absolute;
  right: 20px;
  top: 26px;
  cursor: pointer;
}
.table-typeP.vote th:nth-child(6) em .img {
  width: 12px;
  height: 12px;
  background-position: -250px -350px;
  margin-right: 7px;
  position: relative;
  vertical-align: middle;
}
.table-typeP.vote th:nth-child(6) em:hover {
}
.table-typeP.vote th:nth-child(6) em:hover .img {
  background-position-y: -375px;
}
.table-typeP.vote tr {
  height: 50px;
}

.table-typeP.unregist {
}
.table-typeP.unregist thead tr {
  height: 80px;
}
.table-typeP.unregist col:nth-child(1) {
  width: 60px;
}
.table-typeP.unregist col:nth-child(2) {
  width: 68px;
}
.table-typeP.unregist col:nth-child(3) {
  width: 270px;
}
.table-typeP.unregist col:nth-child(4) {
  width: auto;
}
.table-typeP.unregist col:nth-child(5) {
  width: 110px;
}
.table-typeP.unregist col:nth-child(6) {
  width: 120px;
}
.table-typeP.unregist col:nth-child(7) {
  width: 120px;
}
.table-typeP.unregist .ellipsis {
  width: 152px;
}
.table-typeP.unregist .black {
  text-align: left;
}

.red {
  color: #e6001b;
}
.red span {
  font-weight: 800 !important;
}
.blue {
  color: #005bea;
}

.table-sign {
  padding: 0 !important;
  width: 33px !important;
}
.table-sign,
.table-sign + td,
.table-sign + th {
  border-left: none !important;
  border-right: none !important;
}
.table-sign + td {
  width: 154px;
}

td.table-sign i.img {
  background-position: -150px 0;
  width: 10px;
  height: 8px;
  left: 12px;
  top: 14px;
}

.table-sign span {
  width: 33px;
  height: 16px;
  display: inline-block;
  border-radius: 10px;
  background: #e6f8fa;
  font-size: 9px;
  color: #00a1b3;
  text-align: center;
  line-height: 18px;
  font-weight: 600 !important;
}
.table-sign.out span {
  background: #ffefed;
  color: #f24130;
}
.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
}

.code-box {
  margin-top: 24px;
  border: 1px solid #d9d9d9;
  font-size: 12px;
  height: 284px;
  color: #4d4d4d;
  border-radius: 3px;
}
.code-box .scroll {
  overflow-y: auto;
  height: 230px;
}
.code-box .title-group {
  height: 40px;
  background: #f0f2f5;
  padding: 13px 20px;
}
.code-box .title-group .title {
  font-size: 12px;
  font-weight: 400;
  color: #808080;
}
.code-box .title-group button {
  float: right;
  margin-top: -7px;
  border-radius: 2px;
}
.code-box .title-group button:hover {
  border: 1px solid #1aaaba;
  color: #1aaaba;
  background: transparent;
}
.code-box .list li {
  display: block;
}

.code-box.code .list li span:first-child {
  width: 58px;
  border-right: 1px solid #e1e3e6;
  padding: 3px 8px 2px;
  display: inline-block;
  text-align: right;
  float: left;
}
.code-box.code .list li span:last-child {
  padding: 3px 20px 2px 80px;
  display: block;
}
.code-box.code .list li:hover {
  background: rgba(26, 170, 186, 0.1);
}
.code-box.api {
  margin-top: 40px;
}
.code-box.api .txt {
  padding: 13px 20px;
  color: #4d4d4d;
  line-height: 20px;
  margin-bottom: 0;
  white-space: pre;
}

.code-box.read {
  margin-top: 0px;
  height: 368px;
}
.code-box.read .scroll {
  height: 322px;
  margin-right: 4px;
}

.code-box.read .list li {
  padding: 13px 20px 14px;
}
.code-box.read .list li.result {
  padding: 0px 20px 0px 30px;
  background: #f7f8fa;
}
.code-box.read .list li.result div {
  padding-bottom: 8px;
}
.code-box.read .list li.result div p {
  padding: 6px 0;
}
.code-box.read .list li.result p {
  padding: 14px 0;
  font-size: 10px;
}
.code-box.read .list li.input .label {
  display: inline-block;
  margin: 6px 6px 0 0;
}
.code-box.read .list li span {
  font-weight: 500;
}
.code-box.read .list li em {
  font-size: 10px;
  color: #b3b3b3;
  margin: 0 10px;
}
.code-box.read input {
  height: 26px;
  font-size: 12px;
  color: #4d4d4d;
  border: 1px solid #d9d9d9;
  padding: 0 9px;
  vertical-align: top;
  width: 400px;
  border-radius: 2px;
  margin: 0 1px 5px;
}
.code-box.read input:-ms-input-placeholder {
  color: #b3b3b3;
}
.code-box.read input::-webkit-input-placeholder {
  color: #b3b3b3;
}
.code-box.read input::-moz-placeholder {
  color: #b3b3b3;
}
.code-box.read input::-moz-placeholder {
  color: #b3b3b3;
}

.txt-type-search:-ms-input-placeholder {
  color: #b3b3b3;
}
.txt-type-search::-webkit-input-placeholder {
  color: #b3b3b3;
}
.txt-type-search::-moz-placeholder {
  color: #b3b3b3;
}
.txt-type-search::-moz-placeholder {
  color: #b3b3b3;
}

/*page*/
.page {
  font-size: 12px;
  text-align: right;
  margin-top: 20px;
  color: #999;
}
.page .pageNum {
  margin: 0 20px;
}
.page p {
  display: inline-block;
}
.page li {
  display: inline-block;
  vertical-align: top;
  margin-left: 4px;
}
.page li span {
  display: inline-block;
  width: 26px;
  height: 26px;
  text-align: center;
  padding-top: 6px;
  cursor: pointer;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
}
.page li span.disabled {
  border-color: #ccc;
  cursor: default;
}
.page li span.disabled:hover {
  border-color: #ccc;
}
.page li span.disabled.start .img {
  background-position: -50px -300px;
}
.page li span.disabled.start:hover .img {
  background-position: -50px -300px;
}
.page li span.disabled.prev .img {
  background-position: -100px -300px;
}
.page li span.disabled.prev:hover .img {
  background-position: -100px -300px;
}
.page li span.disabled.next .img {
  background-position: -150px -300px;
}
.page li span.disabled.next:hover .img {
  background-position: -150px -300px;
}
.page li span.disabled.end .img {
  background-position: -200px -300px;
}
.page li span.disabled.end:hover .img {
  background-position: -200px -300px;
}

.page li span em {
  width: 6px;
  height: 8px;
}
.page li span:hover {
  border: 1px solid #1aaaba;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.05);
}
.page input {
  height: 26px;
  margin: 0 8px;
  text-align: center;
  padding: 0;
  font-size: 12px;
  width: 130px;
  border-radius: 3px;
  font-weight: 600;
  border: 1px solid #d9d9d9;
  color: #808080;
}
.page input:focus {
  border: 1px solid #1aaaba;
}
.page .start .img {
  background-position: -50px -50px;
}
.page .prev .img {
  background-position: -100px -50px;
}
.page .next .img {
  background-position: -150px -50px;
}
.page .end .img {
  background-position: -200px -50px;
}
.page span:hover.start .img {
  background-position: -50px -100px;
}
.page span:hover.prev .img {
  background-position: -100px -100px;
}
.page span:hover.next .img {
  background-position: -150px -100px;
}
.page span:hover.end .img {
  background-position: -200px -100px;
}

input[type='text']:hover.over {
  box-shadow: inset 0 2px 1px 0 rgba(0, 0, 0, 0.15);
  border: 1px solid #1aaaba;
}
input[type='text']:focus.over {
  border: 1px solid #4d4d4d;
}

/*graph*/
.bar-graph-group {
  padding: 50px 0px;
}
.bar-graph-group li {
  padding: 0 40px;
  vertical-align: top;
}
.bar-graph-group > li > p {
  font-size: 14px;
  font-weight: 700;
  margin: 0 0 20px;
  vertical-align: top;
}
.bar-graph-group .info {
  width: 200px;
  display: inline-block;
  margin: 40px 0 0 40px;
}
.bar-graph-group li:last-child .info {
  width: 300px;
}
.bar-graph-group li:last-child {
  padding-right: 0;
}
.bar-graph-group .info p {
  padding: 6px 0;
  position: relative;
}
.bar-graph-group .info p:nth-child(2) {
  font-size: 14px;
  padding-bottom: 24px;
  margin: -6px 0 24px;
}
.bar-graph-group .info p:nth-child(2):after {
  content: '';
  border-bottom: 1px dotted #d9d9d9;
  position: absolute;
  bottom: 0;
  width: calc(100%);
  left: 0;
}
.bar-graph-group .info p span {
  font-size: 18px;
  font-weight: 400;
}
.bar-graph-group .info p em {
  font-size: 18px;
  font-weight: 700;
  color: #4d4d4d;
  margin: 0;
}
.bar-graph-group .info p:nth-child(2) ~ p span {
  font-size: 14px;
  float: right;
  margin-top: -6px;
}
.bar-graph-group .info p.on * {
  color: #1aaaba;
}
.bar-graph-group .info p:nth-child(4),
.bar-graph-group .info p:nth-child(4) * {
  color: #006199;
}

.bar-wrap {
  position: relative;
  margin-top: 6px;
  width: 64px;
  height: 200px;
  display: inline-block;
  vertical-align: top;
}
.bar-container {
  position: relative;
  margin-top: 6px;
  width: 64px;
  height: 200px;
  display: inline-block;
}
.bar-container > div > span {
  font-size: 14px;
  color: #fff;
  font-weight: 400;
  text-align: center;
  width: 100%;
  display: inline-block;
  margin-top: 14px;
}
.bar-container > div > span em {
  font-size: 20px;
  color: #fff;
  font-weight: 700;
}

.bar-foreground {
  z-index: 3;
  background: #00d6ed;
}
.bar-background {
  z-index: 0;
  background: #0071b3;
}
.bar-foreground {
  position: relative;
  width: 64px;
  height: 50%;
  top: 0;
  right: 0;
}
.bar-background {
  position: relative;
  width: 64px;
  height: 50%;
  top: 0;
  right: 0;
}
.bar-foreground:before,
.bar-foreground:after,
.bar-background:before,
.bar-background:after {
  content: '';
  position: absolute;
}
.bar-foreground:after,
.bar-background:after {
  -webkit-transform: skew(-45deg);
  -moz-transform: skew(-45deg);
  -o-transform: skew(-45deg);
  -ms-transform: skew(-45deg);
  transform: skew(-45deg);
}
.bar-foreground:before,
.bar-background:before {
  -webkit-transform: skew(0deg, -45deg);
  -moz-transform: skew(0deg, -45deg);
  -o-transform: skew(0deg, -45deg);
  -ms-transform: skew(0deg, -45deg);
  transform: skew(0deg, -45deg);
}
.bar-foreground:before {
  top: -3px;
  right: -6px;
  width: 6px;
  height: 100%;
  background: #00b9cc;
}
.bar-foreground:after {
  top: -6px;
  left: 3px;
  width: 100%;
  height: 6px;
  background: #00e6ff;
}
.bar-background:before {
  top: -3px;
  right: -6px;
  width: 6px;
  height: 100%;
  background: #005d94;
}
.bar-background:after {
  top: -6px;
  left: 3px;
  width: 100%;
  height: 6px;
  background: #0087d6;
}
.bar-graph-group .disagreed .bar-foreground span {
  display: none;
}
.bar-graph-group .disagreed.full .bar-foreground {
  display: none;
}
.bar-graph-group .agreen .bar-background span {
  display: none;
}
.bar-graph-group .agreen.full .bar-background {
  display: none;
}

/*sort*/
.sort-holder {
  font-size: 12px;
  float: left;
  margin-top: 20px;
  position: relative;
}
.sort-holder > p {
  border-radius: 3px;
  border: 1px solid #d9d9d9;
  height: 26px;
  width: 102px;
  padding: 6px 10px;
  color: #808080;
  cursor: pointer;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
}
.sort-holder > p span {
  font-weight: 400;
  margin-left: 6px;
  color: #b3b3b3;
}
.sort-holder > p:hover {
  border: 1px solid #1aaaba;
  color: #1aaaba;
}
.sort-holder > p .img {
  background-position: 0 -50px;
  width: 7px;
  height: 5px;
  position: absolute;
  right: 10px;
  top: 12px;
}
.sort-holder > p:hover .img {
  background-position: 0 -100px;
}
.sort-holder > p:hover span {
  color: #1aaaba;
}

.sort-holder ul {
  width: 102px;
  border: 1px solid #4d4d4d;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  position: absolute;
  bottom: 28px;
  background: #fff;
  overflow: hidden;
  display: none;
  padding: 5px 0;
}
.sort-holder ul li {
  display: block;
  cursor: pointer;
}
.sort-holder ul li:hover {
  background: #e8f6f8;
  color: #1aaaba;
}

.sort-holder ul li span {
  padding-left: 13px;
  width: 100%;
  display: inline-block;
  height: 32px;
  padding-right: 5px;
  line-height: 32px;
}
.sort-holder ul li:last-child span {
  border-bottom: none;
}
/*info*/
.wallet-info {
  font-size: 16px;
  font-style: 700;
  width: 1160px;
  border: 2px solid #262626;
}
.wallet-info p {
  display: inline-block;
  height: 50px;
  border: 1px solid #262626;
}
.wallet-info li {
  display: block;
}
.wallet-info .label {
  width: 250px;
  background: #262626;
  color: #fff;
  border-bottom: 1px solid #fff;
}

/*nodata*/
.sub .content-wrap.nodata .screen0 {
  margin: 160px 0 0px;
}
.nodata .contents {
  width: 600px;
  height: 260px;
  margin: 0 auto;
  text-align: center;
  padding: 56px 0px 50px;
  position: relative;
}
.nodata .contents .title {
  font-size: 24px;
  padding-left: 224px;
  text-align: left;
}
.nodata .contents .txt {
  font-size: 14px;
  color: #4d4d4d;
  text-align: left;
  padding-left: 224px;
  margin-top: 12px;
}
.nodata .contents .address {
  font-size: 14px;
  color: #4d4d4d;
  word-break: break-all;
  margin-top: 70px;
  font-weight: 800;
  font-family: 'NanumSquare';
  background: #f5f5f5;
  padding: 17px 0;
  border-radius: 30px;
}
.nodata .contents span {
  position: absolute;
  left: 52px;
  top: 40px;
}
.nodata .contents span img {
  width: 130px;
}

.tab-holder {
  font-size: 16px;
  width: 1160px;
  margin: 0 auto 33px;
  position: relative;
}
.tab-holder ul {
  border-bottom: 1px solid #4d4d4d;
  font-size: 0;
}
.tab-holder li {
  display: inline-block;
  vertical-align: bottom;
  padding: 20px 0;
  position: relative;
  text-align: center;
  width: 165px;
  cursor: pointer;
  font-size: 16px;
  font-family: 'Montserrat';
  font-weight: 500;
  letter-spacing: -0.5px;
}
.tab-holder li:not(:last-child) {
  cursor: pointer;
}
.tab-holder li:hover {
  color: #1aaaba;
}
.tab-holder li.on {
  font-weight: 700;
}
.tab-holder li.on:after {
  content: '';
  display: block;
  height: 3px;
  background: #4d4d4d;
  margin: 4px auto;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: -5px;
}

/*tap*/
.contents .txt {
  font-size: 12px;
  color: #4d4d4d;
  padding: 0 30px;
  margin-bottom: -7px;
  text-align: right;
}
.contents .txt span:nth-child(2) {
  float: right;
}
.contents .txt em {
  font-weight: 600;
  margin-left: 6px;
  color: #1aaaba;
  cursor: pointer;
}
.contents .txt em:hover {
  text-decoration: underline;
}
.contents .txt em:first-child {
  color: #4d4d4d;
  cursor: default;
  text-decoration: none;
}
.contents .txt em.mint {
  color: #1aaaba;
  cursor: pointer;
}
.contents .txt em.mint:hover {
  text-decoration: underline;
}
.contents .txt em.gray {
  color: #b3b3b3;
  font-weight: 400;
  cursor: default;
  text-decoration: none;
}
.contents .txt em:nth-child(2) {
  margin-left: 6px;
}
.contents .txt.cont {
  margin-top: 36px;
}
.contents .txt em.scam {
  color: #f24130;
}
.contents .txt em.scam .img {
  width: 12px;
  height: 12px;
  margin-right: 4px;
  vertical-align: middle;
  background-position: -125px 0px;
}
.contents.tokens .txt em:nth-child(2) {
  color: #b3b3b3;
  font-weight: 400;
  cursor: default;
  text-decoration: none;
}
.contents > p .ellipsis {
  width: 150px;
  vertical-align: top;
}
.token-img-link {
  top: 30%;
  left: 45%;
}
.content-wrap .search-group span {
  position: absolute;
  right: 50px;
  top: 0px;
  width: 28px;
  height: 28px;
  cursor: pointer;
  z-index: 1;
  
}
span#search-icon {
  margin-top: -26px;
}
.content-wrap .search-group span .img {
  width: 16px;
  height: 16px;
  background-position: -300px -100px;
  margin: 10px 2px;
}

.content-wrap .search-holder {
  float: right;
  margin-top: -28px;
}
.content-wrap .search-holder .txt {
  font-size: 20px;
  color: rgba(38, 38, 38, 0.5);
  padding: 0;
  height: 28px;
  display: inline-block;
  margin-top: 3px;
}
.content-wrap .search-holder .search-group {
  top: -30px;
}

.content-wrap .search-group.event {
  position: absolute;
  right: 0;
  top: 70px;
}

/*governance*/
.content-wrap.governance .screen0 {
  margin-top: -54px;
}
.content-wrap.governance .screen0 .title {
  padding-top: 55px;
}
.content-wrap.governance .screen0 .title span {
  float: right;
  position: relative;
  right: 20px;
  margin-top: 10px;
  cursor: pointer;
  color: #4d4d4d;
}
.content-wrap.governance .screen0 .title span .img {
  width: 14px;
  height: 14px;
  background-position: -150px -250px;
  margin-right: 8px;
  vertical-align: top;
  position: relative;
  left: 0;
  top: 0;
}
.content-wrap.governance .screen0 .title span:hover .img {
  background-position-y: -275px;
}

.content-wrap.governance .screen0 .contents {
  margin-top: 16px;
}
.content-wrap.governance .screen0 .contents .graph {
  width: 1160px;
  height: 197px;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px #d9d9d9;
  padding: 45px 30px 40px 40px;
  position: relative;
}
.content-wrap.governance .screen0 .contents .graph .bar-group {
  width: 776px;
  color: #fff;
  position: relative;
  margin-top: 14px;
}
.content-wrap.governance .screen0 .contents .graph .bar-group div {
  display: inline-block;
  height: 40px;
  text-align: right;
  padding: 10px;
}
.content-wrap.governance .screen0 .contents .graph .bar-group div.small {
  padding-top: 0;
}
.content-wrap.governance .screen0 .contents .graph .bar-group div.small span {
  font-size: 12px;
  padding-top: 0;
}
.content-wrap.governance .screen0 .contents .graph .bar-group div:nth-child(1) {
  background: #545454;
  color: #fff;
  border-top: 2px solid #757575;
  border-right: 2px solid #474747;
  padding: 1px 10px;
}
.content-wrap.governance
  .screen0
  .contents
  .graph
  .bar-group
  div:nth-child(1)
  span {
  font-size: 10px;
}
.content-wrap.governance .screen0 .contents .graph .bar-group div:nth-child(2) {
  background-image: linear-gradient(to bottom, #00d3eb, #00dbf2);
  top: 22px;
  border-top: 2px solid #00e6ff;
  border-right: 2px solid #00b9cc;
}
.content-wrap.governance .screen0 .contents .graph .bar-group div:nth-child(3) {
  background-image: linear-gradient(to bottom, #006aa8, #0071b3);
  top: 44px;
  border-top: 2px solid #0091e6;
  border-right: 2px solid #005d94;
}
.content-wrap.governance .screen0 .contents .graph .bar-group span {
  font-size: 16px;
  font-weight: 700;
}
.content-wrap.governance .screen0 .contents .graph .bar-group span em {
  font-weight: 300;
  margin-left: 4px;
}
.content-wrap.governance .screen0 .contents .graph .txt {
  margin: 0px 0 0 10px;
  text-align: left;
  padding: 0;
}
.content-wrap.governance .screen0 .contents .graph .txt span {
  float: none;
  margin-right: 20px;
}
.content-wrap.governance .screen0 .contents .graph .txt span i {
  width: 4px;
  height: 12px;
  background: red;
  margin-right: 8px;
  vertical-align: top;
}
.content-wrap.governance .screen0 .contents .graph .txt span:nth-child(3) {
  color: #006199;
}
.content-wrap.governance .screen0 .contents .graph .txt span:nth-child(2) {
  color: #00a1b3;
}
.content-wrap.governance .screen0 .contents .graph .txt span:nth-child(1) {
  color: #4d4d4d;
}
.content-wrap.governance .screen0 .contents .graph .txt span:nth-child(3) i {
  background: #006199;
}
.content-wrap.governance .screen0 .contents .graph .txt span:nth-child(2) i {
  background: #00dbf2;
}
.content-wrap.governance .screen0 .contents .graph .txt span:nth-child(1) i {
  background: #545454;
}

.content-wrap.governance .screen0 .contents .graph .total {
  display: inline-block;
  position: absolute;
  right: 51px;
  top: 70px;
  font-size: 12px;
  width: 230px;
  text-align: center;
}
.content-wrap.governance .screen0 .contents .graph .total p:nth-child(1) {
  color: #b3b3b3;
}
.content-wrap.governance .screen0 .contents .graph .total p:nth-child(1) em {
  font-weight: 100;
}
.content-wrap.governance .screen0 .contents .graph .total p:nth-child(2) {
  margin-top: 18px;
  text-align: center;
}
.content-wrap.governance .screen0 .contents .graph .total p:nth-child(2) span {
  font-size: 24px;
  letter-spacing: -0.6px;
  font-weight: 700;
  height: 24px;
  display: inline-block;
}
.content-wrap.governance .screen0 .contents .graph .total:after {
  content: '';
  position: absolute;
  left: -30px;
  top: -26px;
  width: 1px;
  height: 112px;
  border-left: dotted 1px #d9d9d9;
}
.content-wrap.governance .screen0 .contents ul {
  font-size: 0;
  margin-top: 12px;
}
.content-wrap.governance .screen0 .contents ul li {
  position: relative;
  vertical-align: top;
  margin-right: 12px;
}
.content-wrap.governance .screen0 .contents ul li:last-child {
  margin-right: 0;
}
.content-wrap.governance .screen0 .contents ul li div {
  width: 221px;
  height: 100px;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px #d9d9d9;
  background-color: #ffffff;
  padding: 25px 20px 24px 30px;
}
.content-wrap.governance .screen0 .contents ul li div .itag .img {
  width: 14px;
  height: 14px;
  background-position: -150px -250px;
  margin-left: 6px;
  margin-right: 8px;
  cursor: pointer;
  vertical-align: top;
  position: relative;
  left: 0;
  top: 0;
}
.content-wrap.governance .screen0 .contents ul li p:first-child {
  font-size: 11px;
  color: #b3b3b3;
}
.content-wrap.governance .screen0 .contents ul li p:first-child span {
  height: 18px;
  border-radius: 10px;
  background-color: #545454;
  font-size: 9px;
  color: #fff;
  display: inline-block;
  padding: 5px 15px;
  float: right;
  margin-top: -4px;
}
/* .content-wrap.governance .screen0 .contents ul li div .itag .none{height: 14px; border-radius: 14px; background-color: #fff; font-size: 9px; color: #000; display: inline-block; padding: 5px; float: left;     margin-top: 0px;} */

.content-wrap.governance .screen0 .contents ul li p:last-child {
  font-size: 12px;
  font-weight: 300;
  position: relative;
  text-align: right;
  margin-top: 10px;
}
.content-wrap.governance .screen0 .contents ul li p:last-child span {
  font-size: 24px;
  letter-spacing: -0.6px;
  font-weight: 700;
  height: 24px;
  display: inline-block;
}
.content-wrap.governance .screen0 .contents ul li p:last-child em {
  position: absolute;
  bottom: 4px;
  left: 0;
}
.content-wrap.governance .screen0 .contents ul li:nth-child(1) p:first-child,
.content-wrap.governance .screen0 .contents ul li:nth-child(2) p:first-child {
  font-style: italic;
}
.content-wrap.governance .screen0 .contents ul li sub {
  vertical-align: sub;
}
.content-wrap.governance .screen0 .contents ul li em {
  font-style: normal;
  font-weight: 300;
}

.content-wrap.governance .screen1 {
  margin-top: 70px;
}
.content-wrap.governance .screen1 .wrap-holder,
.content-wrap.governance.on .screen2 .wrap-holder {
  padding: 0 20px;
}
.content-wrap.governance .screen1 .start {
  height: 60px;
  border-radius: 3px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  background: #0071b3;
  font-size: 16px;
  color: #fff;
  font-weight: 600;
  text-align: center;
  line-height: 60px;
}
.content-wrap.governance.on .screen1 .start {
  text-align: left;
}
.content-wrap.governance .search-group {
  position: relative;
}
.content-wrap.governance .search-group span {
  position: relative;
  right: 0;
}
.content-wrap.governance .search-group input[type='checkbox'] + label {
  background-position: -294px -346px;
  font-size: 12px;
  margin-top: 0;
  color: #808080;
  height: 28px;
  border-radius: 3px;
  width: auto;
  padding: 5px 16px 0px 30px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid #d9d9d9;
}
.content-wrap.governance .search-group input[type='checkbox']:checked + label {
  background-position: -294px -396px;
  box-shadow: none;
  background-color: #e6f8fa;
  color: #1aaaba;
  border: 1px solid #e6f8fa;
}
.content-wrap.governance
  .search-group
  input[type='checkbox']:checked
  + label
  em {
  color: #00a1b3;
}
.content-wrap.governance .search-group .search {
  display: inline-block;
  width: 250px;
  position: absolute;
}
.content-wrap.governance .search-group .search input {
  width: 250px;
}
.content-wrap.governance .search-group .search .img {
  position: absolute;
  right: 0;
  margin: 8px;
}
.content-wrap.governance .search-group input[type='checkbox']:disabled + label {
  color: #b3b3b3;
  border: solid 1px #d9d9d9;
  background-color: #f7f7f7;
  background-position: -294px -346px;
  cursor: default;
}

.content-wrap.governance
  .search-group
  .blacklist
  input[type='checkbox']
  + label {
  padding: 5px 15px 0;
  background-position: 100px;
}
.content-wrap.governance
  .search-group
  .blacklist
  input[type='checkbox']:checked
  + label {
  border: solid 1px #ffedeb;
  color: #f24130;
  background: #ffedeb;
  box-shadow: inset 0 1px 0 0 #ffd7d4;
}
.content-wrap.governance .search-group .blacklist {
  position: absolute;
  right: 254px;
  width: auto;
}

.content-wrap.governance .screen2 {
  padding-top: 50px;
}
.content-wrap.governance .screen2 p {
  font-size: 12px;
  color: #b3b3b3;
  margin-top: 19px;
  text-align: right;
}
.content-wrap.governance .screen1 .table-box {
  margin: 0 0 30px;
  border-radius: 0 0 3px 3px;
  border-top: none;
}
.content-wrap.governance .screen1 .start {
  position: relative;
}
.content-wrap.governance .screen1 .start span:nth-child(2) {
  display: none;
  text-align: left;
  margin-left: 40px;
}
.content-wrap.governance.on .screen1 .start span:nth-child(1) {
  display: none;
}
.content-wrap.governance.on .screen1 .start span:nth-child(2) {
  display: block;
}
.content-wrap.governance .screen1 .start + .table-box {
  display: none;
}
.content-wrap.governance.on .screen1 .start {
  border-radius: 3px 3px 0 0;
}
.content-wrap.governance .screen1 .start em {
  position: absolute;
  right: 33px;
  cursor: pointer;
  top: 18px;
  display: none;
}
.content-wrap.governance .screen1 .start em i {
  background-position: -200px -400px;
  width: 24px;
  height: 24px;
  vertical-align: top;
}
.content-wrap.governance.on .screen1 .start em {
  display: inline-block;
}
.content-wrap.governance .screen1 .nodata {
}
.content-wrap.governance .screen1 .nodata {
  text-align: center;
  padding: 50px 0;
}
.content-wrap.governance .screen1 .nodata p:nth-child(1) {
  font-size: 16px;
  font-weight: 700;
  color: #4d4d4d;
}
.content-wrap.governance .screen1 .nodata p:nth-child(2) {
  font-size: 14px;
  color: #808080;
  margin-top: 16px;
}
.content-wrap.governance .sum {
  height: 80px;
  font-size: 12px;
  margin: 0;
  padding: 35px 30px 35px 60px;
  position: relative;
}
.content-wrap.governance .sum span {
  display: inline-block;
}
.content-wrap.governance .sum span:nth-child(1) {
  width: 300px;
}
.content-wrap.governance .sum span:nth-child(2) {
  font-size: 14px;
  text-align: right;
  width: 300px;
}
.content-wrap.governance .sum span:nth-child(3) {
  font-size: 14px;
  text-align: right;
  margin-left: 44px;
}
.content-wrap.governance .sum span:nth-child(2) em,
.content-wrap.governance .sum span:nth-child(3) em {
  font-size: 12px;
  margin-left: 4px;
  font-weight: 300;
}
.content-wrap.governance .sum .btn-vote {
  width: 312px;
  display: inline-block;
  text-align: center;
  position: absolute;
  right: 30px;
  top: 20px;
  height: 42px;
  color: #006199;
  font-weight: 600;
  border-radius: 21px;
  font-size: 16px;
  line-height: 42px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #c2e0f2;
  background-color: #d9f1ff;
  cursor: pointer;
}
.content-wrap.governance .sum .btn-vote:hover {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  background-color: #e6f6ff;
}
.content-wrap.governance .sum .btn-vote:active {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  background-color: #c8e7fa;
}
.content-wrap.governance .sum .btn-vote[disabled]:hover,
.content-wrap.governance .sum .btn-vote[disabled]:active,
.content-wrap.governance .sum .btn-vote[disabled] {
  border: solid 1px #e6e6e6;
  background-color: #f7f7f7;
  color: #b3b3b3;
  box-shadow: none;
  cursor: default;
}

.content-wrap.governance .select-cnt {
  height: 60px;
  margin: 0;
  padding: 20px 30px 20px 20px;
  position: relative;
}
.content-wrap.governance .select-cnt span {
  font-size: 12px;
  font-weight: 700;
}
.content-wrap.governance .select-cnt span:nth-child(2) {
  float: right;
  margin-top: 3px;
}
.content-wrap.governance .select-cnt div {
  position: absolute;
  right: 30px;
  top: 12px;
  color: #00b8cc;
}
.content-wrap.governance .select-cnt div p {
  width: 303px;
  height: 8px;
  margin-top: 5px;
  border-radius: 4px;
  border: solid 1px #00b8cc;
  background-color: #ffffff;
  font-size: 0px;
  padding-top: 6px;
}
.content-wrap.governance .select-cnt div p span {
  width: 153px;
  display: inline-block;
  height: 8px;
  border-radius: 4px;
  background-image: linear-gradient(to bottom, #00bed4, #00d6ed);
  margin-top: -7px;
}

.proposal-list {
  margin-top: 16px;
}
.proposal-list h1 {
  font-size: 22px;
  width: 485px;
  height: 60px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 30px;
  font-weight: 700;
  margin: 6px 0 32px;
  word-break: break-word;
}
.proposal-list h2 {
  font-size: 18px;
  width: 306px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 10px 0 27px;
  font-weight: 700;
}
.proposal-list h3 {
  font-size: 12px;
}
.proposal-list h4 {
  font-size: 10px;
}
.proposal-list .label {
  font-weight: 700;
}
.proposal-list .proposal-status {
  height: 26px;
  width: 116px;
  border-radius: 15px;
  color: #fff;
  margin: 0 16px 0 0;
  font-size: 12px;
  padding: 6px 18px;
  font-weight: 700;
  display: inline-block;
  text-align: center;
}
.proposal-list .percent-group {
  display: inline-block;
  width: 136px;
  position: relative;
}
.proposal-list .percent-group:nth-child(1):after {
  content: '';
  position: absolute;
  right: 0;
  top: 28px;
  width: 1px;
  height: 38px;
  background: #e6e6e6;
}
.proposal-list .percent-group:nth-child(2) {
  margin-left: 30px;
}
.proposal-list .percent-group h4 {
  margin: 10px 0 6px;
}
.proposal-list .percent-group div {
  font-size: 24px;
  display: inline-block;
}
.proposal-list .percent-group div span {
  font-weight: 700;
}
.proposal-list .percent-group div:nth-of-type(1) {
  color: #1aaaba;
  margin-right: 9px;
}
.proposal-list .percent-group div:nth-of-type(2) {
  color: #006199;
}
.proposal-list ul {
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  width: 100%;
  height: 242px;
  padding: 52px 40px 48px 40px;
  font-size: 0;
}
.proposal-list ul li {
  vertical-align: top;
  position: relative;
}
.proposal-list ul li:nth-child(1) {
  padding: 0 50px 0 0;
}
.proposal-list ul li:nth-child(1):after {
  content: '';
  position: absolute;
  right: 0;
  top: 5px;
  width: 1px;
  height: 130px;
  background: #e6e6e6;
}
.proposal-list ul li:nth-child(1) div h3 span {
  color: #1aaaba;
  font-weight: 700;
}
.proposal-list ul li:nth-child(2) {
  padding: 0 30px 0 50px;
}
.proposal-list ul li:nth-child(3) {
  padding: 0 7px 0 30px;
}
.proposal-list ul li:nth-child(4) {
  padding: 0 0 0 7px;
}
.proposal-list ul:not(:first-child) {
  margin-top: 20px;
}
.proposal-list ul li > h4 {
  text-align: center;
  color: #999;
}
.proposal-list ul li.canceled {
  color: #999;
}
.proposal-list ul li.agreen .bar-background span {
  display: none;
}
.proposal-list ul li.disagreed .bar-foreground span {
  display: none;
}
.proposal-list ul li.agreen.full .bar-background {
  display: none;
}
.proposal-list ul li.disagreed.full .bar-foreground {
  display: none;
}
.proposal-list li:nth-child(1) div h3 {
  display: inline-block;
}
.proposal-list .bar-wrap {
  position: relative;
  margin-top: 6px;
  width: 52px;
  height: 120px;
  display: inline-block;
  vertical-align: top;
}
.proposal-list .bar-container {
  margin-top: 6px;
  width: 52px;
  height: 120px;
  display: inline-block;
}
.proposal-list .bar-container > div > span {
  font-size: 10px;
  color: #fff;
  font-weight: 400;
  text-align: center;
  width: 100%;
  display: inline-block;
  margin-top: 10px;
}
.proposal-list .bar-container > div > span em {
  font-size: 14px;
  color: #fff;
  font-weight: 700;
}
.proposal-list .bar-foreground {
  width: 48px;
}
.proposal-list .bar-background {
  width: 48px;
}
.proposal-list .bar-foreground:before {
  top: -2px;
  right: -4px;
  width: 4px;
}
.proposal-list .bar-foreground:after {
  top: -4px;
  left: 2px;
  height: 4px;
}
.proposal-list .bar-background:before {
  top: -2px;
  right: -4px;
  width: 4px;
}
.proposal-list .bar-background:after {
  top: -4px;
  left: 2px;
  height: 4px;
}

.no-proposal {
  text-align: center;
  margin-top: 15%;
}
.no-proposal img {
  width: 440px;
  height: 260px;
}

.toast {
  position: fixed;
  bottom: 110px;
  left: calc(50% - 140px);
  height: 42px;
  padding: 0 30px;
  color: #fff;
  font-size: 16px;
  border-radius: 25px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.3);
  background-color: #333333;
  line-height: 42px;
}
.toast span:first-child {
  font-weight: 700;
  margin-right: 20px;
}
.toast span:first-child em {
  color: rgba(255, 255, 255, 0.5);
}

.pop-search {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  display: none;
  z-index: 1;
}
.pop-search .dimmed {
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0)
  );
  width: 100%;
  height: 100%;
}
.pop-search .search-group {
  position: fixed;
  top: 250px;
  left: calc(50% - 350px);
  width: 700px;
  height: 60px;
  border-radius: 3px;
  box-shadow: 0 30px 40px 0 rgba(0, 0, 0, 0.4),
    inset 0 -1px 0 0 rgba(255, 255, 255, 0.6);
  background-color: #333333;
}
.pop-search span {
  cursor: pointer;
  display: inline-block;
}
.pop-search span .img {
  width: 16px;
  height: 16px;
  background-position: -300px -50px;
  margin: 22px;
}
.pop-search input {
  vertical-align: top;
  font-size: 16px;
  border: none;
  width: 85%;
  padding: 30px 0;
  color: #fff;
}
.pop-search input:hover {
  border: none;
}
.pop-search input:focus {
  border: none;
  color: #fff;
}
.pop-search em {
  position: absolute;
  right: 15px;
  top: 15px;
  width: 30px;
  height: 30px;
  display: none;
  cursor: pointer;
}
.pop-search em .img {
  width: 20px;
  height: 20px;
  background-position: 0 -125px;
  margin: 5px;
}
/*//////////////////////////////*/
/*footer*/
.footer-wrap {
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  position: relative;
  background: #262626;
  margin: 0 auto;
}
.footer-wrap .screen0 {
  height: 52px;
  min-width: 1210px;
}
.footer-wrap .screen0 p {
  font-size: 12px;
  padding: 20px 0;
  display: inline-block;
  opacity: 0.7;
  font-weight: 300;
  font-family: 'Montserrat';
  letter-spacing: -0.3px;
  height: 52px;
}
.footer-wrap li {
  display: inline-block;
  vertical-align: top;
}
.footer-wrap .wrap-holder {
  width: 100%;
  min-width: 1260px;
  background: #262626;
  height: 100%;
}
.footer-wrap .wrap-holder .sns {
  position: absolute;
  float: right;
  right: 50px;
  bottom: 0px;
  height: 52px;
}
.footer-wrap .wrap-holder .sns ul {
  display: inline-block;
  vertical-align: top;
  margin-top: 13px;
}
.footer-wrap .wrap-holder .sns li:not(:first-child) {
  margin-left: 10px;
}
.footer-wrap .wrap-holder .sns li span {
  width: 26px;
  height: 26px;
  display: inline-block;
}
.footer-wrap .wrap-holder .sns .icon .img {
  background-position: 0px -150px;
}
.footer-wrap .wrap-holder .sns .medium .img {
  background-position: -50px -150px;
}
.footer-wrap .wrap-holder .sns .brunch .img {
  background-position: -100px -150px;
}
.footer-wrap .wrap-holder .sns .twitter .img {
  background-position: -150px -150px;
}
.footer-wrap .wrap-holder .sns .facebook .img {
  background-position: -200px -150px;
}
.footer-wrap .wrap-holder .sns .github .img {
  background-position: -250px -150px;
}

.footer-wrap .wrap-holder .sns .icon .img:hover {
  background-position: 0px -200px;
}
.footer-wrap .wrap-holder .sns .medium .img:hover {
  background-position: -50px -200px;
}
.footer-wrap .wrap-holder .sns .brunch .img:hover {
  background-position: -100px -200px;
}
.footer-wrap .wrap-holder .sns .twitter .img:hover {
  background-position: -150px -200px;
}
.footer-wrap .wrap-holder .sns .facebook .img:hover {
  background-position: -200px -200px;
}
.footer-wrap .wrap-holder .sns .github .img:hover {
  background-position: -250px -200px;
}

.footer-wrap .wrap-holder .sns .mainnet {
  display: inline-block;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.7);
  position: relative;
  margin-left: 60px;
}
.footer-wrap .wrap-holder .sns .mainnet:before {
  content: '';
  width: 1px;
  height: 14px;
  background: rgba(255, 255, 255, 0.2);
  position: absolute;
  left: -30px;
  top: 20px;
}
.footer-wrap .wrap-holder .sns .mainnet p {
  cursor: pointer;
}
.footer-wrap .wrap-holder .sns .mainnet p:hover {
  color: #fff;
}
.footer-wrap .wrap-holder .sns .mainnet p:hover .img {
  background-position: -250px -100px;
}
.footer-wrap .wrap-holder .sns .mainnet .img {
  background-position: -250px -50px;
  width: 7px;
  height: 5px;
  margin-left: 6px;
}
.footer-wrap .wrap-holder .sns .mainnet ul {
  font-size: 12px;
  font-weight: 100;
  background: #262626;
  border-radius: 4px;
  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.15);
  color: #ccc;
  bottom: 46px;
  left: -30px;
  position: absolute;
  border: 1px solid #404040;
  display: none;
}
.footer-wrap .wrap-holder .sns .mainnet ul li {
  display: block;
  padding: 7px 24px;
  margin-left: 0;
  text-align: center;
  cursor: pointer;
}
.footer-wrap .wrap-holder .sns .mainnet ul li:hover {
  color: #1aaaba;
}
.footer-wrap .wrap-holder .sns .mainnet ul li:not(:last-child) {
  border-bottom: 1px solid #404040;
}
.footer-wrap .wrap-holder .sns .mainnet ul li span {
  width: auto;
  height: auto;
}
.footer-wrap .wrap-holder .mail {
  position: relative;
  margin-right: 60px;
  letter-spacing: 0;
  padding: 18px 0 0;
  vertical-align: top;
}
.footer-wrap .wrap-holder .mail span {
  color: rgb(255, 255, 255, 0.4);
  margin-right: 10px;
}
.footer-wrap .wrap-holder .mail a {
  color: rgb(255, 255, 255, 0.7);
}
.footer-wrap .wrap-holder .mail a:hover {
  color: #fff;
}
.footer-wrap .wrap-holder .mail:before {
  content: '';
  width: 1px;
  height: 14px;
  background: rgba(255, 255, 255, 0.2);
  position: absolute;
  right: -30px;
  top: 20px;
}
/*popup*/
.popup-wrap {
  z-index: 20;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: none;
}

.popup-wrap .scroll-holder {
}
.popup-wrap .dimmed {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.popup-wrap .popup {
  background: #fff;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border-radius: 4px;
  height: 522px;
  width: 580px;
  padding: 40px;
  text-align: center;
  border-radius: 6px;
  border: solid 2px #262626;
  overflow: hidden;
}
.verify{ 
  width: max-content !important;
  height: max-content !important;
  display:flex;
  flex-flow: column wrap;
  justify-content:center;
  align-items: stretch;

  /* padding: 1em; */
}

.cv-form-container { 
  grid-area: form;
  padding: 1.25em;
}

.upload { 
  grid-area: choosefile;
  padding: 1.25em;

}



.verify-form {
  /* display:flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center; */
}
.popup-wrap .popup .title {
  font-size: 20px;
  font-weight: 500;
  text-align: left;
  padding-bottom: 40px;
  font-weight: 700;
  font-family: 'Montserrat';
  letter-spacing: -0.5px;
}
.popup-wrap .popup .qr {
  width: 150px;
  height: 150px;
  margin: 57px auto 30px;
}
.popup-wrap .popup .qr img {
  width: 150px;
  height: 150px;
}
.popup-wrap .popup p {
  font-size: 16px;
  word-wrap: break-word;
}
.popup-wrap .close {
  position: absolute;
  top: 32px;
  right: 32px;
  cursor: pointer;
}
.popup-wrap .close .img {
  width: 20px;
  height: 20px;
  background-position: -300px 0;
}
.popup-wrap .popup.contract {
  height: 600px;
}
.popup-wrap .scroll {
  overflow-y: auto;
  height: 430px;
  padding: 0 10px 0 10px;
  width: 526px;
}
.popup-wrap .scroll p {
  text-align: left;
  font-size: 12px;
  color: #4d4d4d;
}
.popup-wrap .scroll p.hash {
  font-weight: 800;
  color: #1aaaba;
}
.popup-wrap .scroll .label {
  margin-top: 24px;
  font-weight: 600;
}
.popup-wrap .scroll .txt {
  margin-top: 8px;
}
.popup-wrap .scroll a:hover {
  text-decoration: underline;
}

.popup-wrap.scam {
}
.popup-wrap.scam .popup {
  width: 580px;
  height: 522px;
  border: none;
}
.popup-wrap.scam .popup .box {
  padding: 0 10px;
}
.popup-wrap.scam .popup .icon {
  width: 130px;
  height: 130px;
  margin: 50px auto 27px;
}
.popup-wrap.scam .popup .icon img {
  width: 130px;
  height: 130px;
}

.popup-wrap.scam .popup button:disabled,
.popup-wrap.scam .popup button:disabled:hover,
.popup-wrap.scam .popup button[disabled]:hover,
.popup-wrap.scam .popup button[disabled] {
  background: #e6e6e6 !important;
  color: #b3b3b3 !important;
  border: none !important;
}

.popup-wrap.scam .popup .sub-title {
  font-size: 24px;
  letter-spacing: -0.5px;
  font-weight: 600;
  font-family: 'Montserrat';
  margin-bottom: 10px;
}
.popup-wrap.scam .popup .txt {
  font-size: 14px;
  letter-spacing: -0.5px;
  margin-bottom: 33px;
}
.popup-wrap.scam .popup .address {
  font-size: 12px;
  font-weight: 800;
  height: 36px;
  border-radius: 25px;
  background-color: #f5f5f5;
  padding: 11px 0;
}

.popup-wrap.scam h2 {
  font-size: 12px;
  color: #4d4d4d;
  font-weight: 700;
  text-align: left;
  margin-bottom: 21px;
  margin-top: 10px;
}
.popup-wrap.scam h3 {
  font-size: 12px;
  color: #4d4d4d;
  font-weight: 700;
  text-align: left;
  margin-top: 40px;
}
.popup-wrap.scam h2 span,
.popup-wrap.scam h3 span {
  font-weight: 100;
}
.popup-wrap.scam .drop-box {
  height: 70px;
  margin: 20px 0 30px;
  border: 1px dotted #d9d9d9;
  background: #fbfcfc;
  font-size: 12px;
  position: relative;
  font-weight: 100;
  line-height: 1.5;
}
.popup-wrap.scam .drop-box span {
  margin-top: 18px;
  display: inline-block;
  width: 100%;
}
.popup-wrap.scam .drop-box i:nth-of-type(1) {
  width: 32px;
  height: 34px;
  background-position: -100px -350px;
  position: absolute;
  left: 24px;
  top: 17px;
}
.popup-wrap.scam .drop-box i:nth-of-type(2) {
  width: 22px;
  height: 22px;
  background-position: 0 -350px;
  position: absolute;
  right: 24px;
  top: 24px;
  cursor: pointer;
}
.popup-wrap.scam .drop-box.error {
  border: 1px dotted #f24130;
  background: #ffefed;
}
.popup-wrap.scam .drop-box.error span {
  text-align: left;
  color: #f24130;
  padding: 0 60px 0 74px;
  font-weight: 700;
}
.popup-wrap.scam .drop-box.error .msg {
  font-size: 12px;
  font-weight: 400;
  margin: 28px 0 0 20px;
  padding: 0;
}
.popup-wrap.scam .drop-box.normal {
  border: 1px dotted #1daab9;
  background: #e6f8fa;
}
.popup-wrap.scam .drop-box.normal span {
  text-align: left;
  color: #1daab9;
  padding: 0 60px 0 74px;
}

.popup-wrap.scam .drop-box.normal i:nth-of-type(1) {
  background-position: -150px -350px;
}
.popup-wrap.scam .drop-box.normal i:nth-of-type(2) {
  background-position: -50px -350px;
}

.popup-wrap.scam .select {
  margin-bottom: 50px;
}
.popup-wrap.scam .select {
  background: #333333;
  color: #fff;
  height: 36px;
  width: 100%;
  border-radius: 2px;
  font-size: 14px;
}
.popup-wrap.scam input {
  height: 26px;
  font-size: 12px;
  color: #4d4d4d;
  border: 1px solid #d9d9d9;
  padding: 0 9px;
  vertical-align: top;
  width: 100%;
  border-radius: 2px;
  margin: 0 1px 5px;
}
.popup-wrap.scam .select:hover {
  background: #4d4d4d;
  border: none;
}
.popup-wrap.scam input:-ms-input-placeholder {
  color: #b3b3b3;
}
.popup-wrap.scam input::-webkit-input-placeholder {
  color: #b3b3b3;
}
.popup-wrap.scam input::-moz-placeholder {
  color: #b3b3b3;
}

.popup-wrap.about {
}
.popup-wrap.about .txt {
  font-size: 12px;
  color: #808080;
  letter-spacing: -0.5px;
  line-height: 1.67;
}
.popup-wrap.about .sub-title {
  font-size: 14px;
  font-weight: 600;
  font-family: 'Montserrat';
  margin-bottom: 6px;
}
.popup-wrap.about .popup {
  text-align: left;
  width: 580px;
  height: auto;
  padding: 40px 40px 110px;
  font-size: 20px;
  min-height: 246px;
  position: relative;
  border: none;
  overflow: hidden;
}
.popup-wrap.about .title {
  padding-bottom: 12px;
}
.popup-wrap.about .box {
  margin-top: 50px;
}
.popup-wrap.about .box .txt:not(:last-child) {
  margin-bottom: 26px;
}
.popup-wrap.about .box .txt {
  padding-left: 10px;
}

.popup-wrap.suggest {
  padding: 40px 40px 110px;
}
.popup-wrap.suggest .txt {
  text-align: left;
  font-size: 14px;
  color: #808080;
  letter-spacing: -0.5px;
  line-height: 1.67;
}
/* .popup-wrap.suggest .sub-title{font-size: 14px; font-weight: 600; font-family: 'Montserrat'; margin-bottom: 6px;} */
/* .popup-wrap.suggest .popup{text-align: left; width: 580px;  height: auto;  padding: 40px 40px 110px;  font-size: 20px;  min-height: 246px;  position: relative;  border: none;  overflow: hidden;} */
.popup-wrap.suggest .popup {
  height: 200px;
}
.popup-wrap.suggest .title {
  padding-bottom: 12px;
}

.popup-wrap.commission {
  padding: 40px 40px 110px;
}
.popup-wrap.commission .txt {
  text-align: left;
  font-size: 14px;
  color: #808080;
  letter-spacing: -0.5px;
  line-height: 1.67;
}
.popup-wrap.commission .popup {
  height: 230px;
}
.popup-wrap.commission .title {
  padding-bottom: 12px;
}
.popup-wrap.governance .title{
  padding-bottom: 12px;
}
.popup-wrap.governance .popup{
  height:180px
}
.popup-wrap.governance .txt {
  text-align: left;
  font-size: 14px;
  color: #808080;
  letter-spacing: -0.5px;
  line-height: 1.67;
}
.popup-wrap.sponsor .title{
  padding-bottom: 12px;
}
.popup-wrap.sponsor .popup{
  height:200px
}
.popup-wrap.sponsor .txt {
  text-align: left;
  font-size: 14px;
  color: #808080;
  letter-spacing: -0.5px;
  line-height: 1.67;
}
.size-half {
  width: 50% !important;
}
.size-full {
  width: 100% !important;
}

.list-group::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
.list-group::-webkit-scrollbar-button:start:decrement,
::-webkit-scrollbar-button:end:increment {
  display: block;
  height: 5px;
  background: #fff;
}
.list-group::-webkit-scrollbar-track {
  background: #fff;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0);
}
.list-group::-webkit-scrollbar-thumb {
  height: 10px;
  width: 10px;
  background: rgba(0, 0, 0, 0.15);
  -webkit-border-radius: 8px;
  border-radius: 8px;
}

.contents .scroll::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
.contents .scroll::-webkit-scrollbar-button:start:decrement,
::-webkit-scrollbar-button:end:increment {
  display: block;
  height: 10px;
  background: #fff;
}
.contents .scroll::-webkit-scrollbar-track {
  background: #fff;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0);
}
.contents .scroll::-webkit-scrollbar-thumb {
  height: 50px;
  width: 50px;
  background: rgba(0, 0, 0, 0.15);
  -webkit-border-radius: 8px;
  border-radius: 8px;
}

.popup-wrap .scroll::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
.popup-wrap .scroll::-webkit-scrollbar-button:start:decrement,
::-webkit-scrollbar-button:end:increment {
  display: block;
  height: 10px;
  background: #fff;
}
.popup-wrap .scroll::-webkit-scrollbar-track {
  background: #fff;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0);
}
.popup-wrap .scroll::-webkit-scrollbar-thumb {
  height: 50px;
  width: 50px;
  background: rgba(0, 0, 0, 0.15);
  -webkit-border-radius: 8px;
  border-radius: 8px;
}

.popup-wrap.common .popup {
  width: 400px;
  height: auto;
  text-align: center;
  padding: 66px 40px 110px;
  font-size: 20px;
  min-height: 246px;
  position: relative;
  border: none;
  overflow: hidden;
}
.popup-wrap.common p {
  font-size: 20px;
  font-weight: 700;
  line-height: 1.5;
}
.popup-wrap.common p span {
  color: #1daab9;
}
.popup-wrap.common p:not(.txt_box) {
  font-size: 16px;
  font-weight: 400;
  margin-top: 8px;
  line-height: 1.63;
}
.popup-wrap .btn-type-normal {
  border: transparent;
  background: #1aaaba;
  color: #fff;
}
.popup-wrap .btn-type-normal:disabled {
  background: #dddddd;
}
.popup-wrap .btn-type-normal:hover {
  background: #2bb6c4;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
}
.popup-wrap .btn-type-normal:active {
  background: #1daab9;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0);
}
.popup-wrap .btn-type-fill {
  background-color: #f5f5f5;
  border: transparent;
  color: #333;
}
.popup-wrap .btn-type-fill:hover {
  background: #d8d8d8;
}
.popup-wrap [class^='btn-type'] {
  font-size: 16px;
}
.popup-wrap .btn-holder {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0 60px 40px;
  font-size: 16px;
}
.popup-wrap .btn-holder.full {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0;
  font-size: 0;
}
.popup-wrap .btn-holder button {
  height: 50px;
}
.popup-wrap .btn-holder button:first-child {
  border-radius: 0 0 0 3px;
}
.popup-wrap .btn-holder button:last-child {
  border-radius: 0 0 3px 0;
}

.tooltip.img:hover + .help-layer {
  opacity: 1;
  transition: all 0.5s;
}
.help-layer {
  position: absolute;
  background: #4d4d4d;
  border-radius: 8px;
  padding: 8px 14px;
  color: #fff;
  font-size: 12px;
  top: inherit;
  bottom: 40px;
  left: 10px;
  z-index: 9;
  opacity: 0;
  margin-left: 5px;
  pointer-events: none;
  text-align: left;
}
.help-layer .txt {
  color: rgba(255, 2555, 255, 1);
  font-weight: 300;
  line-height: 16px;
  width: max-content;
  font-size: 12px;
  padding: 0;
  margin: 0;
}
.help-layer .tri {
  width: 0px;
  height: 0px;
  border-top: 8px solid #4d4d4d;
  border-bottom: 8px solid none;
  border-right: 10px solid transparent;
  border-left: 0px solid transparent;
  margin-top: 30px;
  position: absolute;
  left: 16px;
  bottom: -8px;
  margin: 0;
}

.help {
  opacity: 0;
}
.help.animate {
  -webkit-animation: slide_Up 0.5s ease forwards;
  -moz-animation: slide_Up 0.5s ease forwards;
  animation: slide_Up 0.5s ease forwards;
}

@-webkit-keyframes slide_Up {
  0% {
    -webkit-transform: translateY(10px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    opacity: 1;
  }
}

@-moz-keyframes slide_Up {
  0% {
    -moz-transform: translateY(10px);
    opacity: 0;
  }
  100% {
    -moz-transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide_Up {
  0% {
    transform: translateY(10px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

/* 로딩 컴포넌트 */
.loadingDiv {
  width: 100%;
  height: inherit;
  position: relative;
}
.loading {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

/*checkbox*/
.cbox-holder {
  display: table-cell;
  width: auto;
  vertical-align: middle;
  border: 1px solid #ccc;
  height: 48px;
  width: auto;
  padding-left: 31px;
}
[class^='cbox-type'] {
  display: none;
}
[class^='cbox-type'] + label {
  height: 18px;
  line-height: 18px;
  padding: 0 20px 0 27px;
  background-color: transparent;
  background-position: -300px -150px;
  font-size: 12px;
  display: inline-block;
  cursor: pointer;
  color: #666;
}
input[type='checkbox']:checked + label {
  background-position-y: -200px;
  color: #fff;
}

.toggle [class^='cbox-type'] + label {
  height: 20px;
  line-height: 18px;
  padding: 0 20px 0 27px;
  background-position: -300px -300px;
  vertical-align: top;
  margin-top: -4px;
}
.toggle input[type='checkbox']:checked + label {
  background-position-y: -325px;
}
.toggle em.noti {
  margin-left: 20px;
}
.toggle em.noti i._img {
  width: 17px;
  height: 21px;
  background-position: -50px -500px;
  position: relative;
  left: 0;
  vertical-align: top;
  margin-left: 5px;
}
.toggle em.noti input[type='checkbox']:checked + label + i._img {
  background-position-x: 0;
}

/*banner*/
.isBanner {
}
.isBanner .header-wrap {
  top: 80px;
}
.isBanner .root {
  padding-top: 80px;
}
/*.isBanner .footer-wrap{margin-bottom: 80px;}*/
/*.isBanner .blank{height: 234px;}*/

.banner-wrap {
  height: 80px;
  width: 100%;
  background-image: linear-gradient(to bottom, #1a1a1a, #212121);
  text-align: center;
  z-index: 2;
  color: #fff;
  position: fixed;
}
.banner-wrap .banner {
  width: 900px;
  display: inline-block;
  text-align: left;
  position: relative;
  background: url(../image/banner.png) 380px bottom no-repeat;
  background-size: auto 80px;
  height: 100%;
}
.banner-wrap .banner .link {
  display: inline-block;
  height: 80px;
  width: 660px;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
}
.banner-wrap .banner img {
  width: 314px;
  height: 44px;
  margin-top: 18px;
}
.banner-wrap .banner .close {
  width: 16px;
  height: 16px;
  background-position: -200px -250px;
  cursor: pointer;
  vertical-align: top;
  margin-top: 2px;
}
.banner-wrap .banner .view {
  position: absolute;
  display: inline-block;
  right: 0;
  top: 30px;
}
.banner-wrap .banner > i {
  position: absolute;
  background: url(../image/banner_b.png) left top no-repeat;
  background-size: auto 50px;
  opacity: 0;
}
.banner-wrap .banner > i:nth-of-type(1) {
  animation-delay: 0.3s;
  animation-duration: 3s;
  left: 472px;
  top: 40px;
  width: 24px;
  height: 22px;
  background-position-x: 0;
}
.banner-wrap .banner > i:nth-of-type(2) {
  animation-delay: 0.8s;
  animation-duration: 3s;
  left: 656px;
  top: 44px;
  width: 36px;
  height: 30px;
  background-position-x: -35px;
}
.banner-wrap .banner > i:nth-of-type(3) {
  animation-delay: 1.5s;
  animation-duration: 2.5s;
  left: 510px;
  top: 16px;
  width: 42px;
  height: 42px;
  background-position-x: -80px;
}
.bubble {
  -webkit-animation: bubble 2s ease infinite;
  -moz-animation: bubble 2s ease infinite;
  animation: bubble 2s ease infinite;
}

.cv-label {
  text-align: left;
  font-size:  .8em !important;
  padding: .3em;
}

th#age-format {
  color: #3498db;
  cursor: pointer;
}
.div-tooltip {
  position: relative;
  display: inline-block;
}

.div-tooltip .tooltiptext {
  visibility: hidden;
  width: 180px;
  background-color: #6c757e;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  margin-top: 4px;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: 100%;
  /* left: 10%; */
  margin-left: -96px;
}
.div-tooltip .tooltiptext::after {
  content: " ";
  position: absolute;
  bottom: 100%;  /* At the top of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #6c757e transparent;
}
.div-tooltip:hover .tooltiptext {
  visibility: visible;
  color: white;
  font-size: 12px;
  font-weight: 600;
}

.tx-screen0 {
    background-color: black;
    padding-top: 120px;
    min-height: 500px;
}
h2.tx-title {
  color: #b6b6b6;
  padding-left: 42px;
  font-size: 25px;
  font-weight: bold;
}

@-webkit-keyframes bubble {
  0% {
    -webkit-transform: translateY(5px);
    opacity: 0;
  }
  10% {
    -webkit-transform: translateY(0px);
    opacity: 1;
  }
  70% {
    -webkit-transform: translateY(0px);
    opacity: 1;
  }
  80% {
    -webkit-transform: translateY(5px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(5px);
    opacity: 0;
  }
}

@-moz-keyframes bubble {
  0% {
    -moz-transform: translateY(5px);
    opacity: 0;
  }
  10% {
    -moz-transform: translateY(0px);
    opacity: 1;
  }
  70% {
    -moz-transform: translateY(0px);
    opacity: 1;
  }
  80% {
    -moz-transform: translateY(5px);
    opacity: 0;
  }
  100% {
    -moz-transform: translateY(5px);
    opacity: 0;
  }
}

@keyframes bubble {
  0% {
    transform: translateY(5px);
    opacity: 0;
  }
  10% {
    transform: translateY(0px);
    opacity: 1;
  }
  70% {
    transform: translateY(0px);
    opacity: 1;
  }
  80% {
    transform: translateY(5px);
    opacity: 0;
  }
  100% {
    transform: translateY(5px);
    opacity: 0;
  }
}
